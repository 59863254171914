import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { setupStore } from './store/store';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n';
import './index.scss';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';

const store = setupStore();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <DndProvider backend={HTML5Backend}>
          <App />
        </DndProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
);

const toastRoot = document.getElementById('react_toast') as HTMLElement;

ReactDOM.createRoot(toastRoot).render(
  <React.StrictMode>
    <DefaultToastContainer />
  </React.StrictMode>,
);