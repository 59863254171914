import { HttpResponse } from 'utils/hooks/http/history.http';
import { tecRMIAxios } from '../../http';
import { IManualListMainGroup } from '../interface/manuals.interface';

class ManualsHttp {
  private static VehicleHasManuals = '/Manuals/VehicleHasManuals';
  private static ManualList = '/Manuals/ManualList';
  private static ManualHtml = '/Manuals/ManualHtml';
  private static ErrorCodeSearchHtml = '/Manuals/ErrorCodeSearchHtml';

  public async fetchRMIVehicleHasManuals(params: {}): Promise<void> {
    return tecRMIAxios.get(ManualsHttp.VehicleHasManuals, { ...params });
  }
  public async fetchRMIVehicleManualsList(params: {}): Promise<HttpResponse<IManualListMainGroup[]>> {
    return tecRMIAxios.get(ManualsHttp.ManualList, { params });
  }
  public async fetchRMIVehicleManualHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(ManualsHttp.ManualHtml, { params });
  }
  public async fetchRMIVehicleErrorCodeHtml(params: {}): Promise<HttpResponse<string>> {
    return tecRMIAxios.get(ManualsHttp.ErrorCodeSearchHtml, { params });
  }
}

export default ManualsHttp;
