import React, { useEffect, useState } from 'react';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import useHttp from 'utils/hooks/useHttp';
import Promotion from 'components/Promotions/Default/Promotion';
import './AutomotorHomePage.scss';
import { useTranslation } from 'react-i18next';
import { IData, IDataResponse } from 'models/order';
import OrderTable from '../espace-automotor-order/order-list/order-table/OrderTable';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { useNavigate } from 'react-router-dom';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { getTariffs } from 'store/selectors/automotor-tariff.selector';
import TariffsAutomotor from './TariffsAutomotor/TariffsAutomotor';
import i18n from 'i18n';

type Props = {};

const AutomotorHomePage: React.FC<Props> = () => {
  const http = useHttp();
  const navigate = useNavigate();
  const [promotionItems, setPromotionItems] = useState<IPromotionDetails[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();
  const [data, setData] = useState<IData[]>([]);
  const [loading, setLoading] = useState(false);
  const tariffs = useSelector(getTariffs);


  useEffect(() => {
    getPromotions();
    getOrderList({ per_page: 5 });
  }, [i18n.language]);

  function getPromotions() {

    http.promotions.activePromotions().then((res) => {
      setPromotionItems(res.data);
    });
  }

  function getOrderList(params) {
    setLoading(true);
    http.order
      .getOrders({ ...params })
      .then((res) => {
        const { data } = res.data as IDataResponse;

        setData(data);

      })
      .finally(() => {
        setLoading(false);
      });
  }

  const hiddenColumns = isMobile || isTablet ? { hiddenColumns: ['time', 'proforma_id', 'user_email'] } : {};

  return <div className="automotor-home-page">
    <div className="orders-table">
      <div className="title-table">{t('_espace_order_title')}</div>
      <OrderTable data={data} loading={loading} hiddenColumns={hiddenColumns} />
      <div className="button-table">
        <DefaultButton text={t('_all_orders')} onClick={() => navigate('/automotor-online/order/list')} />
      </div>
    </div>

    <div className={`promotion ${promotionItems.length == 0 ? '' : 'promotion-show'} slideshow-container`}>
      {promotionItems.length ?
        <Promotion
          items={promotionItems}
          slidesPerView="auto"
          navigation={!(isMobile || isTablet) && promotionItems.length != 1}
          pagination={isMobile || isTablet}
          autoplay={promotionItems.length != 1}
          loop={promotionItems.length != 1}
          iconsArrowLeft={faChevronLeft}
          iconsArrowRight={faChevronRight}
        />
        : null
      }
    </div>

    <TariffsAutomotor tariffs={tariffs} />

  </div >;
};

export default AutomotorHomePage;


