import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import './CustomWebcam.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose } from '@fortawesome/free-solid-svg-icons';
import Tesseract from 'tesseract.js';

type Props = {
  setRecognizedText: (text: string) => void;
  setOpenModal: (e: boolean) => void;
  setSelectedType: (text: string | null) => void;
};

const CustomWebcam: React.FC<Props> = (props) => {
  const webcamRef = useRef<Webcam>(null); // Referința pentru captură
  const webcamRefWithBlur = useRef<Webcam>(null); // Referința pentru fluxul cu blur
  const [image, setImage] = useState<string | null>(null);
  const [vinResult, setVinResult] = useState('');
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);    // Progresul recunoașterii
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  // Funcție pentru capturarea imaginii
  const capture = () => {
    if (webcamRef.current && webcamRef.current.video) {
      const video = webcamRef.current.video;

      // Creăm un canvas pentru a decupa zona clară
      const canvas = document.createElement('canvas');

      canvas.width = 600;
      canvas.height = 100; // Dimensiunea zonei clare de 600x100px

      const context = canvas.getContext('2d');

      if (context) {
        // Coordonatele pentru a decupa exact zona clară
        context.drawImage(video, 0, 140, 600, 100, 0, 0, 600, 100); // Aici se aliniaza zona clară corect

        // Obținem imaginea de pe canvas sub formă de URL
        const imageSrc = canvas.toDataURL('image/jpeg');

        setImage(imageSrc); // Setăm imaginea capturată
      }
    }
  };

  const retakePhoto = () => {
    setImage(null); // Resetează imaginea capturată
    setVinResult('');
  };


  const handleSubmitClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.setRecognizedText(vinResult);
    props.setOpenModal(false);
  };

  useEffect(() => {
    const recognizeText = async () => {
      if (image) {
        setLoading(true);
        const canvas = await preprocessImage(image);
        const procesImage = canvas.toDataURL('image/png');

        setSelectedImage(procesImage);

        try {
          const result = await Tesseract.recognize(
            image,
            'eng',
            {
              logger: (m) => {


                if (m.status === 'recognizing text') {
                  setProgress(m.progress * 100);
                }
              }

            },

          );

          console.log(result.data.words);
          console.log(result.data.text);
          const vin = extractVIN(result.data.text);

          console.log(vin);


          setVinResult(vin);


        } catch (error) {
          console.error('Error during text recognition:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    recognizeText();
  }, [image]);

  const extractVIN = (text: string): string => {
    const regex = /[a-zA-Z0-9]{17}/;
    const matchResult = text.match(regex);

    if (!matchResult) { return ''; }

    const vin = matchResult[0].toUpperCase();

    // Verificare suplimentară a VIN-ului
    if (vin.length !== 17 || !/[A-Z]/.test(vin) || !/\d/.test(vin)) { return ''; }

    // Înlocuire caractere invalide
    const cleanedVin = vin.replace(/I/g, '1')
      .replace(/O/g, '0')
      .replace(/Q/g, '0');

    // Verificare finală pentru caracterele de tip spațiu
    return cleanedVin.includes(' ') ? '' : cleanedVin;
  };

  const preprocessImage = (imgSrc: string): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      // Când imaginea s-a încărcat
      img.onload = () => {
        // Creăm un element canvas pentru a prelucra imaginea
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject(new Error('Nu s-a putut obține contextul canvas-ului'));

          return;
        }

        // Setăm dimensiunile canvas-ului pentru a se potrivi cu imaginea
        canvas.width = img.width;
        canvas.height = img.height;

        // Desenăm imaginea pe canvas
        ctx.drawImage(img, 0, 0);

        // Obținem datele imaginii din canvas
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        const threshold = 100; // Pragul de binarizare

        // Binarizarea imaginii: pixelele sub prag devin negre, celelalte devin albe
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3; // Media valorilor RGB ale fiecărui pixel
          const color = avg < threshold ? 0 : 255;  // Negru sau alb

          data[i] = color;     // Canalul roșu
          data[i + 1] = color; // Canalul verde
          data[i + 2] = color; // Canalul albastru
        }

        // Aplicăm modificările în canvas
        ctx.putImageData(imageData, 0, 0);

        // Returnăm canvas-ul procesat
        resolve(canvas);
      };

      // Dacă imaginea nu poate fi încărcată, respingem promisiunea
      img.onerror = () => reject(new Error('Eroare la încărcarea imaginii'));

      // Setăm sursa imaginii
      img.src = imgSrc;
    });
  };


  return (
    <div className="custom-web-cam">
      <div className="back-modal" onClick={() => { props.setSelectedType(null); }}><FontAwesomeIcon icon={faArrowLeft} /> {t('_go_back')}</div>
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="tile-web-cam">
        {t('_local_image')}
      </div>
      <div className="container-cam">
        {!image && (
          <React.Fragment>
            {/* Webcam video cu blur */}
            <Webcam
              ref={webcamRefWithBlur}
              width={600}
              height={400}  // Dimensiune originală a fluxului
              screenshotFormat="image/jpeg"
              videoConstraints={{
                width: 600,
                height: 400,
                facingMode: 'user', // Poți schimba în "environment" pentru camera din spate
              }}
              style={{
                filter: 'blur(8px)', // Aplică blur pe întregul flux video
                position: 'absolute',
                top: 0,

              }}
            />

            {/* Zona clară de 600x100px */}
            <div
              style={{
                position: 'absolute',
                top: '150px', // Poziția ajustată pentru zona clară

                width: '600px',
                height: '100px', // Dimensiunea zonei clare
                border: '2px solid blue',
                overflow: 'hidden',
              }}
            >
              {/* Webcam pentru zona clară (fără efecte de blur) */}
              <Webcam
                ref={webcamRef} // Folosim referința fără blur pentru captură
                width={600}
                height={400}  // Dimensiune originală a fluxului
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  width: 600,
                  height: 400,
                  facingMode: 'user',
                }}
                style={{
                  transform: 'translateY(-150px)', // Decupează și afișează exact zona clară
                }}
              />
            </div>
          </React.Fragment>
        )}
        {image &&
          <div className="image-preview">
            <img src={image} alt="Captured" />

            <progress value={progress} max="100" /> {/* Afișează progresul */}
          </div>
        }
        {selectedImage &&
          < div className="image-preview">

            <img src={selectedImage} alt="Captured" />


          </div>
        }
      </div>


      {
        !image ? (

          <div className="get_image">
            < DefaultButton text={t('_capture')} onClick={capture} />
          </div>
        ) : (
          <div className="get_image">
            < DefaultButton text={t('_take_another_photo')} onClick={retakePhoto} />
          </div>
        )
      }
      <div className="div-input">
        <label className="div-input-label">
          {t('_vin')}
        </label>
        <input
          type="text"
          defaultValue={vinResult}
          className="url-input"

        />
      </div>
      <div className="get_vin">
        <DefaultButton text={t('_get_vin')} onClick={handleSubmitClick} />
      </div>
    </div >
  );
};

export default CustomWebcam;
