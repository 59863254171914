import TextInput from 'components/inputs/TextInput/TextInput';
import Select from 'react-select';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import './StockEditNew.scss';

import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from 'utils/marketplace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { IDataStock, IDataSuppliersValue, IValidateStock } from 'models/stock';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  item: IDataStock | undefined;
};

const StockEditNew: React.FC<Props> = (props) => {
  let isMounted = true;
  const { t } = useTranslation();
  const http = useHttp();
  const { isMobile, isTablet } = useDeviceDetect();

  const [suppliers, setSuppliers] = useState<IDataSuppliersValue[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingModal, setLoadingModal] = useState<boolean>(true);

  const validate = Yup.object().shape({
    reference: Yup.string()
      .trim()
      .required(t('_fild_required'))
      .matches(/^[a-zA-Z0-9]+$/, 'No special characters'),
    quantity: Yup.number()
      .transform((value, originalValue) => (/\s/.test(originalValue) ? NaN : value))
      .required(t('_fild_required'))
      .typeError(t('_must_number')),
  });

  const {
    getValues,
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IValidateStock>({ resolver: yupResolver(validate) });

  useEffect(() => {
    if (isMounted) {
      setLoadingModal(true);

      http.stockHttp
        .getSupplierTypes()
        .then((res) => {

          setSuppliers(
            res.data[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)].map((item) => {
              return { label: item.name, value: item.id };
            }),
          );

          if (props.item) {
            res.data[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)].map((supplier) => {
              if (props.item?.data_supplier.id == supplier.id) {
                setValue('data_supplier', { value: supplier.id, label: supplier.name });
              }
            });
            setValue('reference', props.item.reference);
            setValue('quantity', props.item.quantity);
            setValue('price_eur', props.item.priceEur);
            setValue('price_gbp', props.item.priceGbp);
          }

          setLoadingModal(false);
        })
        .catch(() => {
          setLoadingModal(false);
        });
    }

    return () => {
      isMounted = false;
    };
  }, []);


  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  const onSubmit = async () => {
    setLoading(true);
    const data = getValues();

    const params = {
      quantity: data.quantity,
      reference: data.reference,
      type_supplier: location.pathname.substring(location.pathname.lastIndexOf('/') + 1),
      data_supplier: data.data_supplier?.value,
      priceEur: Number(data.price_eur),
      priceGbp: Number(data.price_gbp),
    };

    http.stockHttp
      .updateStock(String(props?.item?.id), params)
      .then(() => {
        notify(t('_stock_updated'), 'success', t('_success'));
        props.setOpenModal(false);
        props.setSubmit(true);
      })
      .catch(() => {
        notify('error', t('_error'));
        setLoading(false);
      });
  };

  return (
    <div className="stock-create-new-container">
      <div
        className="close-modal"
        onClick={() => {
          props.setOpenModal(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      <div className="title">{t('_stock_update')}</div>
      {loadingModal ? (
        <div className="spinner-info">
          {' '}
          <Spinner />{' '}
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="stock-create-body">
            <div className="row">
              <div className="container">
                <label>{t('_stock_supplier')}</label>
                <Controller
                  control={control}
                  name="data_supplier"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value}
                      className="select-dropdown"
                      styles={customStyles(errors.data_supplier?.label?.type)}
                      onChange={(val) => {
                        onChange(val);
                      }}
                      options={suppliers}
                      placeholder={`${t('_select')}  ${t('_stock_supplier')}`}
                      isSearchable={!isMobile && !isTablet}
                    />
                  )}
                />
                <p className="error">{errors.data_supplier?.label?.type == 'required' ? errors.data_supplier?.label?.message : ''}</p>
              </div>

              <div className={`container ${errors.reference?.type == 'required' || errors.reference?.type == 'matches' ? 'error' : ''}`}>
                <label>{t('_stock_reference')}</label>
                <TextInput {...register('reference')} />
                <p className="error-message">
                  {errors.reference?.type == 'required' || errors.reference?.type == 'matches' ? errors.reference?.message : ''}
                </p>
              </div>
              <div className={`container ${errors.quantity?.type ? 'error' : ''}`}>
                <label>{t('_stock_quantity')}</label>
                <TextInput {...register('quantity')} />
                <p className="error-message">{errors.quantity?.type ? errors.quantity?.message : ''}</p>
              </div>
            </div>
            <div className="row">
              <div className={`container ${errors.price_eur?.type == 'required' || errors.price_eur?.type == 'matches' ? 'error' : ''}`}>
                <label>{t('_price')} EUR</label>
                <TextInput {...register('price_eur')} />
                <p className="error-message">
                  {errors.price_eur?.type == 'required' || errors.price_eur?.type == 'matches' ? errors.price_eur?.message : ''}
                </p>
              </div>
              <div className={`container ${errors.price_gbp?.type == 'required' || errors.price_gbp?.type == 'matches' ? 'error' : ''}`}>
                <label>{t('_price')} GBP</label>
                <TextInput {...register('price_gbp')} />
                <p className="error-message">
                  {errors.price_gbp?.type == 'required' || errors.price_gbp?.type == 'matches' ? errors.price_gbp?.message : ''}
                </p>
              </div>
            </div>
          </div>
          <div className="btn-save">
            <button type="submit">
              {' '}
              {loading ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_stock_update')}</div>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

StockEditNew.displayName = 'StockEditNew';

export default StockEditNew;
