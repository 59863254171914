import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as EreliableLogo } from 'assets/images/logo/logo-ereliable-124x17.svg';
import { useNavigate } from 'react-router-dom';
import './EspaceReliable.scss';

const EspaceReliableCatalog: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();


  return (
    <div className="espace-reliable-catalog-container">
      <div className="espace-reliable-catalog">
        <div className="reliable-catalog-type">
          <EreliableLogo />
          <p>{t('_universal_catalog')}</p>
        </div>

        <div className="reliable-catalog-info">
          <p dangerouslySetInnerHTML={{ __html: t('_espace_catalog_description') }}></p>
        </div>

        <div className="reliable-catalog-button">
          <div className="espace-catalog-button" onClick={() => navigate('/universal-catalog')}>
            <Trans i18nKey="_espace_catalog_button" components={{ span: <span translate="no" /> }} />
          </div>

          {/* {process.env.REACT_APP_MODE === 'preprod' ?
            <a className="espace-catalog-button" href={`${process.env.REACT_APP_PUBLIC_APP}?language=${language}&country=${country}`} target="_blank" rel="noreferrer">{t('_espace_catalog_button')}</a>
            :
            <div className="espace-catalog-button" onClick={() => navigate('/universal-catalog')}> {t('_espace_catalog_button')}</div>
          } */}
        </div>
      </div>
    </div>
  );
};

EspaceReliableCatalog.displayName = 'EspaceReliableCatalog';

export default EspaceReliableCatalog;
