import React, { useEffect, useRef } from 'react';
import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { useSearchParams } from 'react-router-dom';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './CatalogTyresFilter.scss';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
};

const CatalogTyresFilter: React.FC<Props> = (props) => {
  const { control, getValues, reset, setValue } = useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const reliableSearchParams = useReliableCatalogCarQueryParams();
  const { isMobile, isTablet } = useDeviceDetect();

  // useOnClickOutside(filterRef, () => {
  //   props.setOpenFilter!(false);
  // });


  const getOptions = (key) => {
    if (props.filterData && (key in props.filterData)) {
      return (
        props.filterData[key]?.map((item) => ({
          label: item.key,
          value: item.value,
        })) || []
      );
    }
  };


  function handleClick() {
    const params = {
      brand_ids: Array.isArray(getValues()?.brand) ? getValues()?.brand?.map(({ value }) => value) : (getValues()?.brand?.value && [getValues()?.brand?.value]),
      widths: Array.isArray(getValues()?.width) ? getValues()?.width?.map(({ value }) => value) : (getValues()?.width?.value && [getValues()?.width?.value]),
      heights: Array.isArray(getValues()?.height) ? getValues()?.height?.map(({ value }) => value) : (getValues()?.height?.value && [getValues()?.height?.value]),
      diameters: Array.isArray(getValues()?.diameter) ? getValues()?.diameter?.map(({ value }) => value) : (getValues()?.diameter?.value && [getValues()?.diameter?.value]),
      load_indexes: Array.isArray(getValues()?.load_index) ? getValues()?.load_index?.map(({ value }) => value) : (getValues()?.load_index?.value && [getValues()?.load_index?.value]),
      speed_ratings: Array.isArray(getValues()?.speed_rating) ? getValues()?.speed_rating?.map(({ value }) => value) : (getValues()?.speed_rating?.value && [getValues()?.speed_rating?.value]),
      page: searchParams.get('page'),
      category_id: searchParams.get('category_id'),
      q: searchParams.get('q')
    };


    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value || !value.length) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {} as { [key: string]: string | string[] });


    setSearchParams({ ...validParams, ...reliableSearchParams });
    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ brand: '', ah: '', amp: '' });
    setSearchParams({ ...reliableSearchParams, ...{ category_id: String(searchParams.get('category_id')) } });
    props.setOpenFilter!(false);
  }

  useEffect(() => {
    const foundBrands = props.filterData && props.filterData?.brand?.filter(item => {
      return searchParams.getAll('brand_ids').includes(String(item.value));
    });

    if (foundBrands?.length) {
      setValue('brand', foundBrands.map(item => {
        return { label: item.key, value: item.value };
      }));
    }

    const foundWidths = props.filterData && props.filterData?.width?.filter(item => {
      return searchParams.getAll('widths').includes(String(item.value));
    });

    if (foundWidths?.length) {
      setValue('width', foundWidths.map(item => {
        return { label: item.key, value: item.value };
      }));
    }

    const foundHeights = props.filterData && props.filterData?.height?.filter(item => {
      return searchParams.getAll('heights').includes(String(item.value));
    });

    if (foundHeights?.length) {
      setValue('height', foundHeights.map(item => {
        return { label: item.key, value: item.value };
      }));
    }

    const foundDiameters = props.filterData && props.filterData?.diameter?.filter(item => {
      return searchParams.getAll('diameters').includes(String(item.value));
    });

    if (foundDiameters?.length) {
      setValue('diameter', foundDiameters.map(item => {
        return { label: item.key, value: item.value };
      }));
    }

    const foundLoadIndexes = props.filterData && props.filterData?.load_index?.filter(item => {
      return searchParams.getAll('load_indexes').includes(String(item.value));
    });

    if (foundLoadIndexes?.length) {
      setValue('load_index', foundLoadIndexes.map(item => {
        return { label: item.key, value: item.value };
      }));
    }

    const foundSpeedRatings = props.filterData && props.filterData?.speed_rating?.filter(item => {
      return searchParams.getAll('speed_ratings').includes(String(item.value));
    });

    if (foundSpeedRatings?.length) {
      setValue('speed_rating', foundSpeedRatings.map(item => {
        return { label: item.key, value: item.value };
      }));
    }

  }, [props.filterData]);

  return (
    <div ref={filterRef} className={`tyre-filters-catalog ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">
        <div className="dropdowns-container">
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_tyre_brand')}</label>
            <Controller
              control={control}
              name="brand"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('brand')}
                  placeholder={t('_tyre_brand')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}

                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_width')}</label>
            <Controller
              control={control}
              name="width"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('width')}
                  placeholder={t('_width')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_height')}</label>
            <Controller
              control={control}
              name="height"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('height')}
                  placeholder={t('_height')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_diameter')}</label>
            <Controller
              control={control}
              name="diameter"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('diameter')}
                  placeholder={t('_diameter')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_load_index')}</label>
            <Controller
              control={control}
              name="load_index"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('load_index')}
                  placeholder={t('_load_index')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_speed_rating')}</label>
            <Controller
              control={control}
              name="speed_rating"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('speed_rating')}
                  placeholder={t('_speed_rating')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 150 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default CatalogTyresFilter;
