import React, { useEffect, useState } from 'react';
import './CatalogsPartsPage.scss';
import List from 'components/List/List';
import { IPartsCategory, ICategiryByItem } from 'models/vehicle-catalogs';
import Spinner from 'components/spinner/Spinner';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import Masonry from 'react-masonry-css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { IManualsListItemMps } from 'utils/rmi/interface/manuals.interface';
import { TbBook } from 'react-icons/tb';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import TextInput from 'components/inputs/TextInput/TextInput';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'utils/hooks/redux';
import { setActivePartCategory } from 'store/slices/car-parts-categories.slice';
import { ICategorieGroup } from 'applications/automotor/components/espace/navbar/Navbar.model';
import { NavLink } from 'react-router-dom';

type Props = {
  loading: boolean;
  activeCategory: IPartsCategory | null;
  onCategoryClick: (category) => void;
  handleSubcategoryClick: (param: IGetCarPartDataParams) => string;
  onClickHide: () => void;
  itemMpIds: ICategiryByItem[] | null;
  vehicleManuals: IManualsListItemMps[] | null;
  redirectToManual: (event, manualId) => void;
};

const CatalogsPartsPageView: React.FC<Props> = (props) => {
  const { watch, register } = useForm();
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const { partsCategories } = useSelector((state: RootState) => state.carPartsCategories);
  const [filteredCategories, setFilteredCategories] = useState<IPartsCategory[] | ICategorieGroup[]>(partsCategories);
  const searchInput = watch('search_input');
  const dispatch = useAppDispatch();

  const getSubcategories = (subcategories, searchCriteria) => {
    return subcategories.reduce((acc, subcategory) => {
      if (subcategory.name.toLowerCase().includes(searchCriteria.toLowerCase())) {
        acc.push(subcategory);

        return acc;
      }

      if (subcategory.subcategories) {

        const subcategoriesDeepCopy = getSubcategories(subcategory.subcategories, searchCriteria);

        if (subcategoriesDeepCopy.length > 0) {

          acc.push({ ...subcategory, subcategories: subcategoriesDeepCopy });
        }
      }

      return acc;
    }, []);
  };

  const searchCategories = (categories: IPartsCategory[] | ICategorieGroup[], searchCriteria: string) => {
    if (!categories) { return; }


    let categoriesDeepCopy = JSON.parse(JSON.stringify(categories));

    categoriesDeepCopy = categoriesDeepCopy.filter((category) => {
      if (category.name?.toLowerCase().includes(searchCriteria?.toLowerCase())) {
        return true;
      }

      if (category.subcategories && searchCategories(category.subcategories, searchCriteria).length > 0) {
        return true;
      }

      return false;
    });

    categoriesDeepCopy = categoriesDeepCopy.map(category => {
      if (category.subcategories) {
        category.subcategories = getSubcategories(category.subcategories, searchCriteria);
      }

      return category;
    });

    return categoriesDeepCopy;
  };

  const hasManual = (subcategory) => {
    if (!props.itemMpIds) {
      return false;
    }

    return props.itemMpIds.find(
      (itemMpId) =>
        itemMpId.category.code_groupe === subcategory.code_groupe &&
        itemMpId.category.code_repere === subcategory.code_repere &&
        itemMpId.category.code_ssgroupe == subcategory.code_ssgroupe,
    );
  };

  const findManual = (filteredItemsIds) => {
    if (!props.vehicleManuals) {
      return null;
    }

    return props.vehicleManuals.find((manual) => filteredItemsIds.itemMpIds.find((itemsId) => itemsId === manual.ItemMpId));
  };

  const getManual = (subcategory) => {
    const filteredItemsIds = hasManual(subcategory);

    if (!filteredItemsIds) {
      return null;
    }

    const manual = findManual(filteredItemsIds);

    if (manual) {
      return manual.Manuals[0].ManualId;
    }

    return null;
  };

  useEffect(() => {
    setFilteredCategories(partsCategories);
  }, [partsCategories]);

  useEffect(() => {
    const filteredPartsCategories = partsCategories.length
      ? searchCategories(partsCategories, searchInput)
      : [];

    setFilteredCategories(filteredPartsCategories);
    dispatch(setActivePartCategory(filteredPartsCategories[0]));
  }, [searchInput]);

  return (
    <div className="catalog-parts">
      <div className="catalog-parts-search">
        <div className="catalog-search-input">
          <TextInput placeholder="Search categories..." {...register('search_input')} />
        </div>
      </div>
      <div className="catalog-parts-page">
        {isMobile ? (
          !props.activeCategory && (
            <div className={`categories-list ${props.loading ? 'loading' : ''}`}>
              {props.loading ? <Spinner class="catalogs-spinner" /> : (
                filteredCategories.length == 0 ? (
                  <div className="categories-no-data">{t('_no_data')}</div>
                ) : (
                  <List
                    content={filteredCategories.map((item) => {
                      return { ...item, id: item.code_groupe, name: item.name };
                    })}
                    images={filteredCategories.map((item) => item.image)}
                    // className="parts-list"
                    itemId="code_groupe"
                    itemClassName="parts-list"
                    onItemClick={props.onCategoryClick}
                  />
                )
              )}
            </div>
          )
        ) : (
          <div className={`categories-list categories-sidebar ${props.loading ? 'loading' : ''}`}>
            {props.loading ? <Spinner class="catalogs-spinner" /> : (
              filteredCategories.length == 0 ? (
                <div className="categories-no-data">{t('_no_data')}</div>
              ) : (
                <List
                  content={filteredCategories.map((item) => {
                    return { ...item, id: item.code_groupe, name: item.name };
                  })}
                  images={filteredCategories.map((item) => item.image)}
                  // className="parts-list"
                  // itemClassName="parts-list"
                  itemId="code_groupe"
                  itemClassName="parts-list"
                  onItemClick={props.onCategoryClick}
                  activeCategory={props.activeCategory}
                />
              )
            )}
          </div>
        )}

        {props.activeCategory && (
          <div className="masonry-content categories-content">
            {isMobile ? (
              <div className="return" onClick={() => props.onClickHide()}>
                <FontAwesomeIcon icon={faArrowCircleLeft} /> {t('_return')}
              </div>
            ) : null}
            <div className="items">
              <Masonry breakpointCols={3} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                {props.activeCategory?.subcategories.map((items, key) => {
                  return (
                    <div key={key + items.name} className="item">
                      <div className="title">{items.name}</div>
                      {items.subcategories.map((item, key) => {
                        const manualId = item.disponible ? getManual(item) : null;

                        return (
                          // <div
                          //   onClick={() => {
                          //     if (item.disponible) {
                          //       props.handleSubcategoryClick({
                          //         code_ssgroupe: items.code_ssgroupe,
                          //         code_groupe: item.code_groupe,
                          //         code_repere: item.code_repere,
                          //         reference_count: item.reference_count,
                          //       });
                          //     }
                          //   }}
                          //   className={`subcategory ${item.disponible === 0 ? 'disabled' : ''}`}
                          //   key={key + item.name}
                          // >
                          //   {manualId ? (
                          //     <TbBook className="manual-icon" onClick={(event) => props.redirectToManual(event, manualId)} />
                          //   ) : (
                          //     ''
                          //   )}
                          //   {item.name}
                          // </div>
                          <div key={key} className="subcategory-item">
                            {
                              manualId ? (
                                <TbBook className="manual-icon" onClick={(event) => props.redirectToManual(event, manualId)} />
                              ) : (
                                ''
                              )
                            }
                            <NavLink className={`subcategory ${item.disponible === 0 ? 'disabled' : ''}`} key={key + item.name} to={props.handleSubcategoryClick({
                              code_ssgroupe: items.code_ssgroupe,
                              code_groupe: item.code_groupe,
                              code_repere: item.code_repere,
                              reference_count: item.reference_count,
                            })
                            }
                            >
                              {item.name}
                            </NavLink>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Masonry>
            </div>
          </div>
        )
        }
      </div >
    </div >
  );
};

CatalogsPartsPageView.displayName = 'CatalogCarPageView';

export default CatalogsPartsPageView;
