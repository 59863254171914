import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import TextInput from 'components/inputs/TextInput/TextInput';
import useHttp from 'utils/hooks/useHttp';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Spinner from 'components/spinner/Spinner';
import './ForgotPassword.scss';
import { notify } from 'utils/marketplace';
import { Trans, useTranslation } from 'react-i18next';


type Props = {}

const ForgotPassword: React.FC<Props> = () => {
  const [sendAgainStatus, setSendAgainStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const http = useHttp();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { t } = useTranslation();

  const submitHandler = data => {
    setLoading(true);

    http.forgotPassword.forgotPassword({
      email: data.email,
      url: `${window.location.origin}/restore-password`,
    })
      .then(() => {
        setSendAgainStatus(true);
      })
      .finally(() => {
        setLoading(false);
      }).catch((err) => {
        notify(err.email[0]);
      });
  };

  return (
    <div className="forgot-password-wrapper">
      <div className="content">
        {sendAgainStatus ? (<p className="title">
          <Trans
            i18nKey="_recovery_email"
            components={{ br: <br /> }}
          />
        </p>) : (<p className="description">
          {t('_forgot_password')}
        </p>
        )}
        <div className="formWrapper">
          <form onSubmit={handleSubmit(submitHandler)} className="inputs">
            {sendAgainStatus && <div className="description">
              <Trans
                i18nKey="_email_not_received_note"
                components={{ br: <br /> }}
              /></div>}
            <label htmlFor="#email" className={`${errors.email && 'error'}`}>
              {t('_email')}
              <TextInput
                placeholder="distributeur@mail.com"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('_email_invalid'),
                  },
                })}
              />
              {errors.email?.type === 'pattern' && <p className="error-message">{t('_email_invalid')}</p>}
              {errors.email?.type === 'required' && <p className="error-message">{t('_email_required')}</p>}
              <div className="back-to-signin" onClick={() => navigate('/login')}>{t('_back_to_sign_in')}</div>
            </label>
            <DefaultButton
              text={
                <span>
                  {loading && (
                    <Spinner class="Spinner"></Spinner>
                  )}
                  {sendAgainStatus ? t('_send_again') : t('_reset')}
                </span>
              }
              disabled={loading}
              type="submit"
            />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
