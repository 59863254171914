import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogTopSales from './CatalogTopSales';
import CatalogTopSalesMobile from './CatalogTopSalesMobile/CatalogTopSalesMobile';

const CatalogTopSalesView:React.FC = () => {
  const {isMobile} = useDeviceDetect();

  return (
    isMobile ? <CatalogTopSalesMobile /> : <CatalogTopSales />
  );
};

export default CatalogTopSalesView;