import React, { useEffect, useRef, useState } from 'react';
import { CATALOG_TYRE, CATALOG_BATTERY, CATALOG_CONSUMABLE, CATALOG_OIL } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import { isRetailerMecadepotChild } from 'utils/isRetailerMecadepotChild';
import { IBodyResponseTopSales } from 'models/car-parts.model';
import { useTranslation } from 'react-i18next';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import CatalogNavbar from 'components/navbar/catalog-navbar/CatalogNavbar';
import TextInput from '../../../../components/inputs/TextInput/TextInput';
import Spinner from 'components/spinner/Spinner';
import useHttp from 'utils/hooks/useHttp';
import axios from 'axios';
import './CatalogTopSales.scss';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import { useLocation, useSearchParams } from 'react-router-dom';
import { filterObject } from 'utils/utils';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import List from 'components/List/List';
import i18n from 'i18n';
import { ReactComponent as Oil } from 'assets/Icons/oil.svg';
import { ReactComponent as Battery } from 'assets/Icons/battery_new.svg';
import { ReactComponent as Tyre } from 'assets/Icons/tyre.svg';
import { FaSprayCan } from 'react-icons/fa';
import Pagination from 'components/pagination/Pagination';
import { ICustomValidation } from 'utils/hooks/http/search-by-reference.http';

const CatalogTopSales: React.FC = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const http = useHttp();
  const [loading, setLoading] = useState(true);
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseTopSales>();
  const [searchInput, setSearchInput] = useState('');
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [activeCategoryInfo, setActiveCategoryInfo] = useState<ICategory>();
  const [activeCategory, setActiveCategory] = useState<{ id: number }>({ id: searchParams.get('category_id') ? Number(searchParams.get('category_id')) : categories[0]?.id });
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const [clearable, setClearable] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>(null);

  function getParams() {
    return {
      search: searchParams.get('search')
    };
  }


  function getTopSalesCategories(cancelToken) {

    setLoadingCategories(true);
    const filteredParams = filterObject(getParams());

    setLoading(true);
    http.retailerHttp.getTopSalesCategories(validateParams(filteredParams), cancelToken).then((res) => {

      setCategories(res);
      const categoryId = searchParams.get('category_id');

      let firstValidCategory: ICategory | undefined;

      if (categoryId) {
        firstValidCategory = res.find((category) => category.id == Number(categoryId));
      } else {
        firstValidCategory = res.find((category) => category.number_of_products !== 0);
      }


      if (firstValidCategory) {
        const param = filterObject({
          ...filteredParams,
          category_type: String(firstValidCategory?.category_type)
        });

        setActiveCategoryInfo(firstValidCategory);
        getData(validateParams(param));
      } else {

        setRefData(undefined);
        setLoading(false);
      }
    }).catch(() => {
      setRefData(undefined);
      setLoading(false);
    }).finally(() => {
      setLoadingCategories(false);
    });

    return cancelToken;

  }


  function getData(data) {
    setLoading(true);
    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      page,
    };

    const validParams = validateParams(params);

    http.retailerHttp
      .getProductTopSales(validParams)
      .then((res) => {
        setRefData(res.data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

      });
  }


  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };


  useEffect(() => {
    const cancelToken = axios.CancelToken.source();

    // Verifică dacă `category_type` este singurul parametru
    if (((searchParams.has('category_id') && Array.from(searchParams.keys()).length === 1) || searchParams.has('page')) && categories.length) {

      const params = getParams();

      const categoryId = searchParams.get('category_id');

      let firstValidCategory: ICategory | undefined;

      if (categoryId) {
        firstValidCategory = categories.find((category) => category.id == Number(categoryId));
      } else {
        firstValidCategory = categories.find((category) => category.number_of_products !== 0);
      }

      getData(validateParams(filterObject({ ...params, ... { category_type: String(firstValidCategory?.category_type) } })));
    } else {

      getTopSalesCategories(cancelToken.token);


    }


    return () => {
      cancelToken.cancel('Anulare datorită schimbării de dependență.');
    };
  }, [i18n.language, search]);

  const onCategoryClick = (category) => {
    setActiveCategoryInfo(category);
    searchParams.set('category_id', category.id);
    setSearchParams(searchParams);
    setPage(1);
  };

  useEffect(() => {

    const firstValidCategory = categories.find((category) => category.number_of_products !== 0);

    setActiveCategory({ id: Number(searchParams.get('category_id')) || Number(firstValidCategory?.id) });
  }, [categories, searchParams.get('category_id')]);

  const imageCategory = (types) => {
    return types.map((type) => {
      switch (type) {
        case 'tyre':
          return Tyre;
        case 'oil':
          return Oil;
        case 'battery':
          return Battery;
        case 'consumable':
          return FaSprayCan;
        default:
          return null;
      }
    }).filter(Boolean); // Elimină valorile `null` sau `undefined` din array
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  useEffect(() => {
    searchParams.delete('category_id');
    searchParams.delete('page');

    if (searchInput) {
      searchParams.set('search', searchInput);
    } else {
      searchParams.delete('search');
    }

    setSearchParams(searchParams);

  }, [searchInput]);


  const catalogType = (type: string): 'CATALOG_TYRE' | 'CATALOG_OIL' | 'CATALOG_BATTERY' | 'CATALOG_CONSUMABLE' => {


    switch (type) {
      case 'tyre':
        return CATALOG_TYRE;
      case 'oil':
        return CATALOG_OIL;
      case 'battery':
        return CATALOG_BATTERY;
      case 'consumable':
        return CATALOG_CONSUMABLE;
      default:
        return CATALOG_TYRE; // Default value
    }
  };

  const customValidations: ICustomValidation[] = [
    {
      regex: new RegExp(/^.{3,}$/),
      message: t('_ref_search_min_length'),
    }
  ];

  const onClickReset = () => {
    ref.current!.value = '';
    setSearchInput('');
    setClearable(false);

  };

  const refInputChange = (event) => {
    const inputData = event.target.value.replace(/\s/g, '').length > 0 ? event.target.value : event.target.value.trim();

    setSearchInput(inputData);

    if (inputData.length) {

      setClearable(true);
    } else {
      setClearable(false);
    }
  };


  return (
    <div className="top_sales-wrapper">
      <CatalogNavbar title="_top_sales" showSortBy={isRetailerMecadepotChild()} />
      <div className="separator"></div>

      <div className="filterOptions">
        <span className="search-parts-title">{t('_search')}</span>
        <TextInput
          name="search"
          placeholder="Ex: 205 55 16 91"
          onChange={(e) => { refInputChange(e); }}
          customValidations={customValidations}
          withIcon
          clearable={clearable}
          onClickReset={onClickReset}
          ref={ref}
        />
        {(refData?.data.length && refData?.data.length > 0 && !isRetailerMecadepotChild()) ? <SortBy /> : null}
        <div className="results">
          {refData?.data.length} {t('_results')}
        </div>

        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />

      </div>
      <div className="retailer-catalog-top_sales-container">
        <div className="catalog-top_sales-sidebar">
          {categories && categories.length ? (
            <List
              content={categories?.map((item) => {
                return { ...item, id: item.id, name: item.name };
              })}
              images={imageCategory(categories?.map((item) => item.category_type))}
              itemClassName="parts-list"
              itemId="id"
              onItemClick={onCategoryClick}
              activeCategory={activeCategory}
            />
          ) : null}
        </div>
        <div className="wrapperItems">
          {loadingCategories || loading ? <Spinner class="top-sales-spinner" />
            :
            (refData?.data?.length ? (
              <div>
                <ProductsViewModeChanger catalog={catalogType(activeCategoryInfo?.category_type || 'tyre')} viewMode={viewMode} data={refData?.data} />
              </div>
            ) : (
              <div className="no-parts-data">
                <div className="notFoundSvg">
                  <PartsNotFoundImage />
                </div>
                <h1>{t('_top_sales_not_found')}</h1>
              </div>
            ))
          }
          {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && !loading ? (
            <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
          ) : (
            null
          )}
        </div>
      </div>
    </div>
  );
};

export default CatalogTopSales;
