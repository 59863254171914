import React from 'react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import CatalogTyres from './CatalogTyres';
import CatalogTyresMobile from './CatalogTyresMobile/CatalogTyresMobile';

const CatalogTyresView: React.FC = () => {
  const { isMobile } = useDeviceDetect();

  return (
    isMobile ? <CatalogTyresMobile /> : <CatalogTyres />
  );
};

export default CatalogTyresView;