import React, { useState, useContext, useEffect } from 'react';

import { SidebarContext, ISidebarContext } from 'context/SidebarContext';
import useHttp from 'utils/hooks/useHttp';
import { IItemClick } from 'models/catalog.model';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { IConsumableBrands, IConsumableCategories, TabCategories } from './Sidebar.model';
import { IPartsCategory } from 'models/vehicle-catalogs';
import CategoriesSidebarView from './CategoriesSidebar/CategoriesSidebar.view';
import { IGetCarPartDataParams } from 'models/car-parts.model';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import RetailerSidebarView from './RetailerSidebar.view';
import { IOilCatalogFilter } from 'utils/hooks/http/oils-catalog.http';
import { IBatteryBrands, IBatteryCategory, IVehicleTyresFilterResponce } from 'views/Sidebar/Sidebar.model';

type Props = {};

const RetailerSidebar: React.FC<Props> = () => {
  const { sideBarTab, setSidebarTab } = useContext(SidebarContext) as ISidebarContext;
  const http = useHttp();
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);
  const navigate = useNavigate();
  const [tyresCategories, setTyresCategories] = useState<IVehicleTyresFilterResponce[]>([]);
  const [oilCategories, setOilCategories] = useState<IOilCatalogFilter>();
  const [consumableBrands, setConsumableBrands] = useState<IConsumableBrands[]>([]);
  const [consumableCategories, setConsumableCategories] = useState<IConsumableCategories[]>([]);
  const [batteryBrands, setBatteryBrands] = useState<IBatteryBrands[]>([]);
  const [batteryCategories, setBatteryCategories] = useState<IBatteryCategory[]>([]);

  const vehicleSelected = localStorage.getItem('carSelected') ? JSON.parse(localStorage.getItem('carSelected') as string) : null;
  const [categories, setCategories] = useState<IPartsCategory[]>([]);
  const [showDetails, setShowDetails] = useState(false);
  const [activeCategory, setActiveCategory] = useState<IPartsCategory>();
  const reliableCatalogCarQueryParams = useReliableCatalogCarQueryParams();

  useEffect(() => {
    if (!mounted && sideBarTab) {
      fetchRequests();
    }
  }, [sideBarTab]);

  const fetchRequests = () => {
    setLoading(true);
    let resp;

    if (vehicleSelected != null) {

      const params = getParams();

      resp = Promise.all([
        http.vehicleCatalogs.getCategories(String(vehicleSelected.car.id), params),
        http.oilCatalogHttp.getOilFilters({ ...params, vehicle_id: vehicleSelected.car.id }),
        http.catalogs.fetchVehicleTyresCategories({ source: vehicleSelected.car.source, vehicle_id: vehicleSelected.car.id }),
        http.catalogs.getBrandsWithConsumables(),
        http.catalogs.getCategoriesWithConsumables(),
        http.catalogs.getBrandsWithBatteries(),
        http.catalogs.getCategoriesWithBatteries()

      ]);
    } else {
      resp = Promise.all([
        http.catalogs.getOilCategories(),
        http.catalogs.getCategoriesWithConsumables(),
        http.catalogs.getBrandsWithBatteries(),
        http.catalogs.getCategoriesWithBatteries()
      ]);

    }

    resp.then((res) => {
      setMounted(true);

      if (vehicleSelected != null) {
        setCategories(res[0]);
        setOilCategories(res[1].data);
        setTyresCategories(res[2].data);
        setConsumableBrands(res[3].data);
        setConsumableCategories(res[4].data);
        setBatteryBrands(res[5].data);
        setBatteryCategories(res[6].data);
      } else {
        setOilCategories(res[0]);
        setConsumableCategories(res[1].data);
        setBatteryBrands(res[2].data);
        setBatteryCategories(res[3].data);
      }
    })
      .finally(() => {
        setLoading(false);
      });


  };

  const getParams = () => {
    let params;

    if (Object.keys(reliableCatalogCarQueryParams).length > 2) {
      params = reliableCatalogCarQueryParams;
    } else {
      params = {
        source: vehicleSelected.car.source,
        vehicle_identifier: vehicleSelected.car.vehicle_identifier,
        ...(vehicleSelected.car_information.vin && { vin: vehicleSelected.car_information.vin }),
      };
    }

    return params;
  };


  const closeSidebar = () => {
    setSidebarTab(null);
  };

  const onItemClick = (item: IItemClick) => {

    switch (item.type) {
      case 'oil': {
        let searchParams = getParams();

        searchParams = {
          ...searchParams,
          ...(item.params == 'quantity' && { 'quantity': String(item.id) }),
          ...(item.params == 'brand' && { 'brand_id': String(item.id) }),
          ...(item.params == 'category' && { 'category_id': String(item.id) }),
          ...(item.params == 'family' && { 'family': String(item.id) }),
          ...(item.params == 'viscosity' && { 'viscosity': String(item.id) }),
        };


        navigate({
          pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/oils`,
          search: `?${createSearchParams(searchParams)}`,
        });

        break;
      }

      case 'tyre': {

        let searchParams = getParams();


        searchParams = {
          ...searchParams,
          ...(item.params == 'season' && { 'season_ids[]': String(item.id) }),
          ...(item.params == 'brand' && { 'brand_ids[]': String(item.id) }),
        };


        navigate({
          pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/tires`,
          search: `?${createSearchParams(searchParams)}`,
        });

        break;
      }
      case 'consumables': {

        let searchParams = getParams();

        searchParams = {
          ...searchParams,
          ...(item.params == 'brand_ids' && { 'brand_id': String(item.id) }),
          ...(item.params == 'category_ids' && { 'category_id': String(item.id) }),
        };

        navigate({
          pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/consumables`,
          search: `?${createSearchParams(searchParams)}`,

        });

        break;
      }

      case 'battery': {

        let searchParams = getParams();

        searchParams = {
          ...searchParams,
          ...(item.params == 'brand_ids' && { 'brand_ids': String(item.id) }),
          ...(item.params == 'category_ids' && { 'category_id': String(item.id) }),
        };

        navigate({
          pathname: `/reliable/rmi/${vehicleSelected.car.id}/catalogs/batteries`,
          search: `?${createSearchParams(searchParams)}`,

        });

        break;
      }

      case 'pieces': {
        setSidebarTab(null);
        setShowDetails(true);
        setActiveCategory(item.item);


        break;
      }
      default: {
        return;

        break;
      }
    }

    closeSidebar();
  };

  const onSidebarSubCategoryClick = async (params: Omit<IGetCarPartDataParams, 'catalog_id' | 'page' | 'per_page'>) => {


    let paramsObject: { [key: string]: string } = {};

    if (Object.keys(reliableCatalogCarQueryParams).length) {
      paramsObject = reliableCatalogCarQueryParams;
    } else {
      paramsObject = {
        source: vehicleSelected.car.source,
        vehicle_identifier: vehicleSelected.car.vehicle_identifier,
        ...(vehicleSelected.car.vin && { vin: vehicleSelected.car.vin }),
      };
    }

    paramsObject['code_groupe'] = String(params.code_groupe);
    paramsObject['code_ssgroupe'] = String(params.code_ssgroupe);
    paramsObject['code_repere'] = String(params.code_repere);
    paramsObject['page'] = String(1);

    navigate({
      pathname: `reliable/rmi/${vehicleSelected.car.id.toString()}/catalogs/parts/part-catalog`,
      search: `?${new URLSearchParams(paramsObject).toString()}`,
    });
    setShowDetails(false);
  };

  const onReturnButtonClick = () => {
    setShowDetails(false);
    setSidebarTab(TabCategories.Pieces);
  };

  const onCloseButtonClick = () => {
    setShowDetails(false);

  };


  return (
    <React.Fragment >
      {
        sideBarTab && (
          <RetailerSidebarView
            sideBarTab={sideBarTab}
            loading={loading}
            oilCategories={oilCategories}
            tyresCategories={tyresCategories}
            consumableBrands={consumableBrands}
            consumableCategories={consumableCategories}
            batteryBrands={batteryBrands}
            batteryCategories={batteryCategories}
            categories={categories}
            setSidebarTab={setSidebarTab}
            closeSidebar={closeSidebar}
            onItemClick={onItemClick}

          />
        )
      }
      {showDetails && <CategoriesSidebarView activeCategory={activeCategory} onCloseButtonClick={onCloseButtonClick} onSidebarSubCategoryClick={onSidebarSubCategoryClick} onReturnButtonClick={onReturnButtonClick} />}
    </React.Fragment>
  );
};

RetailerSidebar.displayName = 'RetailerSidebar';

export default RetailerSidebar;
