import {
  ICatalogInfo,
  IClientInfo,
  IFilterForm, IManufacturerMappingList,
  IPromise,
  IPromiseCountries,
  IPromiseZoneCommercials,
  ISupplierMapping,
  ISupplierMappingList,
  ITecomMapping,
  ITestForm,
  ITestResponce,
  IUpdateForm,
} from 'interfaces/selectClient';
import { IHttpResponse, serverAxios } from '../../http';
import { IOption } from 'interfaces/select';

class SelectClinetHttp {
  private static clients = 'seller/clients';
  private static zoneCommercials = 'seller/zone-commercials';
  private static countries = 'seller/countries';
  private static saveClientForSeller = 'seller/clients/';
  private static clientInfo = 'amerigo/supplier-amerigo/clients/';
  private static supplierMapping = 'amerigo/supplier-amerigo/supplier-mapping';
  private static teccomMapping = 'amerigo/supplier-amerigo/teccom-mapping/';
  private static catalogInfo = 'amerigo/supplier-amerigo/catalog-info';
  private static sellerClients = '/seller/amerigo-clients/all';
  private static uploadSellerFiles = '/seller/upload-notification-tariff';
  private static importCatalogOil = '/retailer/catalog_oil/import';
  private static importCatalogConsumable = '/retailer/catalog_consumable/import';
  private static importCatalogTyre = '/retailer/catalog_tyre/import';
  private static importCatalogBattery = '/retailer/catalog_battery/import';
  private static importCatalogNonTecdoc = 'retailer/catalog_non_tec_dock/import';
  private static importPreviewCatalogNonTecdoc = 'retailer/catalog_non_tec_dock/preview-file';
  private static suppliersForCatalogNonTecdoc = 'retailer/catalog_non_tec_dock/supplier-mapping-list';
  private static manufacturerForCatalogNonTecdoc = 'retailer/catalog_non_tec_dock/manufacturer-mapping-list';

  public async selectClient(param: IFilterForm): Promise<IPromise> {
    return serverAxios.get(SelectClinetHttp.clients, { params: param });
  }

  public async zoneCommercials(): Promise<IPromiseZoneCommercials> {
    return serverAxios.get(SelectClinetHttp.zoneCommercials);
  }
  public async countries(): Promise<IPromiseCountries> {
    return serverAxios.get(SelectClinetHttp.countries);
  }
  public async saveClientForSeller(id: number): Promise<IPromiseCountries> {
    return serverAxios.post(SelectClinetHttp.saveClientForSeller + id);
  }

  public async clientInfo(id: number): Promise<IHttpResponse<IClientInfo>> {
    return serverAxios.get(SelectClinetHttp.clientInfo + id);
  }
  public async supplierMapping(): Promise<IHttpResponse<ISupplierMapping[]>> {
    return serverAxios.get(SelectClinetHttp.supplierMapping);
  }
  public async teccomMapping(id: number): Promise<IHttpResponse<ITecomMapping[]>> {
    return serverAxios.get(SelectClinetHttp.teccomMapping + id);
  }

  public async supplierTest(id: number, data: ITestForm): Promise<IHttpResponse<ITestResponce>> {
    return serverAxios.post('amerigo/supplier-amerigo/test/' + id + '/teccom-mapping', data);
  }
  public async supplierUpdate(id: number, data: IUpdateForm[]): Promise<IHttpResponse<ITecomMapping[]>> {
    return serverAxios.patch('amerigo/supplier-amerigo/clients/' + id + '/teccom-mapping', data);
  }
  public async getCatalogInfo(): Promise<IHttpResponse<ICatalogInfo>> {
    return serverAxios.get(SelectClinetHttp.catalogInfo);
  }
  public async getSellerClients(): Promise<IOption[]> {
    const { data } = await serverAxios.get(SelectClinetHttp.sellerClients);

    return data;
  }
  public async uploadSellerFiles(data: FormData): Promise<[]> {
    return serverAxios.post(SelectClinetHttp.uploadSellerFiles, data, { headers: { 'content-type': 'multipart/form-data' } });
  }

  public async importCatalogOil(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(SelectClinetHttp.importCatalogOil, data, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async importCatalogConsumable(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(SelectClinetHttp.importCatalogConsumable, data, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async importCatalogTyre(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(SelectClinetHttp.importCatalogTyre, data, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async importCatalogBattery(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(SelectClinetHttp.importCatalogBattery, data, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async importCatalogNonTecdoc(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(SelectClinetHttp.importCatalogNonTecdoc, data, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async importPreviewCatalogNonTecdoc(data: FormData): Promise<IHttpResponse<[]>> {
    return serverAxios.post(SelectClinetHttp.importPreviewCatalogNonTecdoc, data, { headers: { 'content-type': 'multipart/form-data' } });
  }
  public async supplierMappingList(): Promise<ISupplierMappingList[]> {
    const { data } = await serverAxios.get(SelectClinetHttp.suppliersForCatalogNonTecdoc);

    return data;
  }
  public async manufacturerMappingList(): Promise<IManufacturerMappingList[]> {
    const { data } = await serverAxios.get(SelectClinetHttp.manufacturerForCatalogNonTecdoc);

    return data;
  }
}

export default SelectClinetHttp;
