import React, { useEffect, useState } from 'react';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import Spinner from 'components/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import ProductsViewMode from 'components/ProductsViewMode/ProductsViewMode';
import { DEFAULT_VIEW } from 'components/ProductsViewMode/ProductsViewMode.constants';
import ProductsViewModeChanger from 'components/ProductsViewModeChanger/ProductsViewModeChanger';
import { CATALOG_BATTERY, CATALOG_CONSUMABLE, CATALOG_OIL, CATALOG_TYRE } from 'components/ProductsViewModeChanger/products-view-mode-changer-utils';
import { IBodyResponseTopSales } from 'models/car-parts.model';
import './CatalogTopSalesMobile.scss';
import SortBy, { SortByParams } from 'components/SortBy/SortBy';
import { sortByOptions } from 'components/SortBy/SortBy.map';
import { useLocation, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Pagination from 'components/pagination/Pagination';
import TopSalesNavbar from 'components/navbar/top-sales/TopSalesNavbar';
import { filterObject } from 'utils/utils';
import { ICategory } from 'views/Sidebar/Sidebar.model';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import List from 'components/List/List';
import { ReactComponent as Oil } from 'assets/Icons/oil.svg';
import { ReactComponent as Battery } from 'assets/Icons/battery_new.svg';
import { ReactComponent as Tyre } from 'assets/Icons/tyre.svg';
import { FaSprayCan } from 'react-icons/fa';

const CatalogTopSalesMobile: React.FC = () => {
  const { t } = useTranslation();
  const http = useHttp();
  const [loading, setLoading] = useState(true);
  const [refData, setRefData] = useState<IBodyResponseTopSales>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingCategories, setLoadingCategories] = useState(true);
  const [activeCategoryInfo, setActiveCategoryInfo] = useState<ICategory>();
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [viewMode, setViewMode] = useState(user?.user_settings.products_view_mode || DEFAULT_VIEW);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const { search } = useLocation();
  const [totalCount, setTotalCount] = useState(0);

  function getParams() {
    return {
      search: searchParams.get('search')
    };
  }

  function getTopSalesCategories(cancelToken) {

    setLoadingCategories(true);
    const filteredParams = filterObject(getParams());

    http.retailerHttp.getTopSalesCategories(validateParams(filteredParams), cancelToken).then((res) => {
      setCategories(res);
      setTotalCount(res.reduce((total, item) => total + Number(item.number_of_products), 0));

    }).catch(() => {
      setRefData(undefined);
      setLoading(false);
      setLoadingCategories(false);
    }).finally(() => {
      setLoadingCategories(false);
    });

    return cancelToken;

  }


  function getData(data) {
    setLoading(true);
    setRefData(undefined);
    const sortBySelectedOption = sortByOptions.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [];

    const sortParams: SortByParams = {
      sort_by_column: String(searchParams.get('sort') ?? ''),
      sort_by_asc: String(searchParams.get('asc') ?? sortBySelectedOption?.default_sort_asc ?? ''),
    };

    const params = {
      ...data,
      ...sortParams,
      page,
    };

    const validParams = validateParams(params);

    http.retailerHttp
      .getProductTopSales(validParams)
      .then((res) => {
        setRefData(res.data);

      }).finally(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);

      });
  }


  const validateParams = (data) => {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (!value) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {});
  };


  useEffect(() => {

    // Verifică dacă `category_type` este singurul parametru
    if (searchParams.has('category_id')) {

      let params = getParams();

      const categoryId = searchParams.get('category_id');

      let firstValidCategory: ICategory | undefined;

      if (categoryId) {
        firstValidCategory = categories.find((category) => category.id == Number(categoryId));
        params = { ...params, ... { category_type: String(firstValidCategory?.category_type) } };
      }

      getData(validateParams(filterObject(params)));
    }


  }, [i18n.language, search]);
  useEffect(() => {
    const cancelToken = axios.CancelToken.source();


    getTopSalesCategories(cancelToken.token);


    return () => {
      cancelToken.cancel('Anulare datorită schimbării de dependență.');
    };
  }, [i18n.language]);


  const onCategoryClick = (category) => {
    setActiveCategoryInfo(category);
    searchParams.set('category_id', category.id);
    setSearchParams(searchParams);
    setPage(1);
    setIsCategorySelected(true);
  };

  const onClickReturn = () => {
    searchParams.delete('category_id');
    searchParams.delete('page');
    setSearchParams(searchParams);
    setIsCategorySelected(false);
  };


  const catalogType = (type: string): 'CATALOG_TYRE' | 'CATALOG_OIL' | 'CATALOG_BATTERY' | 'CATALOG_CONSUMABLE' => {


    switch (type) {
      case 'tyre':
        return CATALOG_TYRE;
      case 'oil':
        return CATALOG_OIL;
      case 'battery':
        return CATALOG_BATTERY;
      case 'consumable':
        return CATALOG_CONSUMABLE;
      default:
        return CATALOG_TYRE; // Default value
    }
  };


  const imageCategory = (types) => {
    return types.map((type) => {
      switch (type) {
        case 'tyre':
          return Tyre;
        case 'oil':
          return Oil;
        case 'battery':
          return Battery;
        case 'consumable':
          return FaSprayCan;
        default:
          return null;
      }
    }).filter(Boolean); // Elimină valorile `null` sau `undefined` din array
  };

  const handlePageClick = (pageN: number) => {
    setPage(pageN);
  };

  return (
    <div className="view-catalog top_sales-catalog-wrapper-mobile">
      <TopSalesNavbar title="_top_sales" />

      <div className="filterOptions">
        {refData?.data?.length && refData?.data?.length > 0 && searchParams.get('category_id') ? <SortBy /> : null}

        <div className="results">
          {!isCategorySelected && totalCount ? totalCount + ' ' + t('_results') : (refData?.data?.length && refData?.data?.length > 0 ? refData?.total_count + ' ' + t('_results') : <Spinner class="extra-small" />)}
        </div>
        <ProductsViewMode viewMode={viewMode} setViewMode={setViewMode} />
      </div>

      {isCategorySelected ? (
        <div className="top_sales-catalog-heading">
          <div className="top_sales-category-return" onClick={() => onClickReturn()}><FontAwesomeIcon icon={faCircleArrowLeft} />{t('_return')}</div>
          <div className="top_sales-category-title">{activeCategoryInfo?.name}</div>
        </div>
      ) : null}

      <div className="retailer-catalog-top_sales-container-mobile">
        {!isCategorySelected ? (
          <div className={`catalog-top_sales-sidebar-mobile ${loadingCategories ? 'loading' : null}`}>
            {loadingCategories ? <Spinner class="small" /> : (
              categories && categories.length ? (
                <List
                  content={categories?.map((item) => {
                    return { ...item, id: item.id, name: item.name };
                  })}
                  images={imageCategory(categories?.map((item) => item.category_type))}
                  itemClassName="parts-list-catalog-item"
                  itemId="id"
                  onItemClick={onCategoryClick}
                />
              ) : null
            )}
          </div>
        ) : (
          <div className="wrapperItems">
            {loading ? (
              <Spinner class="car-parts-spinner" />
            ) : refData?.data?.length ? (
              <ProductsViewModeChanger catalog={catalogType(activeCategoryInfo?.category_type || 'tyre')} viewMode={viewMode} data={refData?.data} />
            ) : (
              <div className="no-parts-data">
                <div className="notFoundSvg">
                  <PartsNotFoundImage />
                </div>
                <h1>{t('_oils_not_found')}</h1>
              </div>
            )}
          </div>
        )}
      </div>
      {(refData && refData?.total_pages && refData?.page && refData?.total_pages > 1) && isCategorySelected ? (
        <Pagination pageCount={refData.total_pages} handlePageClick={handlePageClick} forcePage={refData.page} />
      ) : (
        null
      )}
    </div>
  );
};

export default CatalogTopSalesMobile;
