import { faPen, faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'components/spinner/Spinner';
import { ICategory } from 'interfaces/batteryCategory';

import React from 'react';
import { useTranslation } from 'react-i18next';
import './ItemEshopOilCategory.scss';

type Props = {
  data: ICategory[],
  loading: boolean,
  openModalOilCategory: (id: number) => void;
  deleteItem: (id: number) => void
};

const ItemEshopOilCategoryView: React.FC<Props> = (props) => {

  const { t } = useTranslation();

  return (
    <div className="item-container-shop-oil-category">
      {
        props.loading ? <Spinner /> :

          props.data.length ?
            props.data.map((item, index) => {
              return (
                <div className="item-shop" key={index}>

                  <div className="header">

                    <div className="content">
                      <div className="title">{item.name}</div>
                    </div>
                  </div>
                  <div className="body">
                    <div className="img">
                      <img src={item.image ? `${item.image}` : '/images/car-part-placeholder.png'} onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = '/images/car-part-placeholder.png';
                      }}
                      />
                    </div>
                  </div>
                  <div className="footer">
                    {item.editable ?
                      <div className="cart">
                        <div className="cart-button">
                          <button className="button-edit" onClick={() => props.openModalOilCategory(item.id)}>
                            <FontAwesomeIcon icon={faPen} className="icon-edit" />
                          </button>
                          <button className="button-delete" onClick={() => props.deleteItem(item.id)}>
                            <FontAwesomeIcon icon={faRemove} className="icon-delete" />
                          </button>

                        </div>
                      </div>
                      : null
                    }

                  </div>

                </div>
              );
            }) :
            <div className="no-data">
              {t('_no_data')}
            </div>

      }
    </div>
  );
};

ItemEshopOilCategoryView.displayName = 'ItemEshopOilCategoryView';

export default ItemEshopOilCategoryView;
