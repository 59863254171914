import React, { useEffect, useRef, useState } from 'react';

import './CartSlidingV2.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';

import useHttp from 'utils/hooks/useHttp';
import { GetCartResponse } from 'models/cart.model';
import { getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import { CartTableItem } from 'applications/retailer/views/Cart/CartTable/CartTable.model';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Spinner from 'components/spinner/Spinner';
import { useAppDispatch } from 'utils/hooks/redux';
import { setCartCurentPageSlice, setCartSlice, setCartSliceQuantityToolbar, setCartSliceTotalPrice, setCartTotalPagesSlice } from 'store/slices/cart-v2.slice';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';


type Props = {
  isActive: boolean;
};

const CartSlidingV2: React.FC<Props> = (props) => {

  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { quantityToolbarSlice, cart, totalPriceSlice, curentPageSlice, totalPagesSlice } = useSelector((state: RootState) => state.cartV2Slice);
  const [dataState, setDataState] = useState<CartTableItem[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [totalPartsPrice, setTotalPartsPrice] = useState<number>(0);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const dispatch = useAppDispatch();
  const http = useHttp();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(0); // Pagina curentă
  const [totalPages, setTotalPages] = useState<number>(0); // Pagina curentă
  const [hasMore, setHasMore] = useState<boolean>(true); // Mai sunt date de încărcat
  const containerRef = useRef<HTMLDivElement | null>(null); // Referință pentru containerul scroll-ului


  const handleCartButtonClick = () => {
    navigate('/cart');
  };

  useEffect(() => {
    if (quantityToolbar != quantityToolbarSlice && props.isActive) {
      setHasMore(true);
      setCurrentPage(0);
      setTotalPages(0);
      fetchData(1);

    }

    if (quantityToolbar == quantityToolbarSlice && props.isActive) {

      setLoadingData(false);
      setCurrentPage(curentPageSlice);
      setTotalPages(totalPagesSlice);
      setTotalPartsPrice(totalPriceSlice);
      setTotalQuantity(quantityToolbarSlice);
      setDataState(cart);
    }
  }, [props.isActive]);


  useEffect(() => {
    if (dataState.length) {
      dispatch(setCartSlice(dataState));

    }

  }, [dataState]);


  const handleScroll = () => {
    if (!containerRef.current || loadingData || !hasMore) { return; }

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

    if (scrollHeight - scrollTop <= clientHeight + 50) {
      fetchData(currentPage + 1); // Încarcă pagina următoare
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [currentPage, loadingData, hasMore]);


  const fetchData = async (page = 1) => {

    try {


      if (page === 1) {
        setDataState([]); // Resetează datele la început pentru prima pagină
        setHasMore(true); // Permite încărcarea paginilor suplimentare
        setCurrentPage(0); // Resetează pagina curentă
      }

      if (page > totalPages && dataState.length) {
        setHasMore(false); // Nu mai sunt pagini de încărcat

        return;
      }

      setLoadingData(true);
      const response = await http.cart.getCartData(page, 10).finally(() => { // Încarcă doar 10 produse
        setLoadingData(false);

      });

      setTotalPages(response.total_pages);
      const data = response.data.map((item: GetCartResponse): CartTableItem => {
        const images = item.images?.filter(image => {
          if (!image) { return false; }

          const lastDotIndex = image.lastIndexOf('.');
          const extension = image.substring(lastDotIndex + 1).toLocaleLowerCase();

          if (extension === 'jpg' || extension === 'jpeg' || extension === 'png' || extension === 'bmp') {
            return extension;
          }
        });

        const workTimeDescription: string = (item?.work_description ? `<span class="work-time-description">${item?.work_description?.data_supplier_name} ${item?.work_description?.data_supplier_reference}</span>` : '');

        return {
          id: item.cart_id,
          available: item.available,
          image: (images ? images[0] : (item?.image ? item?.image : item?.data_supplier_logo)),
          barCode: { image: `${getMarketplaceBaseUrl()}/${item?.barcode?.image}`, code: item?.barcode?.code },
          categories: item?.category ? item?.category : (item?.subcategory ? item?.subcategory.name : ''),
          quantity_availability: { quantity: item.cart_quantity || 0, availability: typeof (item.available) === 'number' ? item.available : item.available.quantity },
          price: item?.price?.price || 0,
          priceVAT: String(item?.price?.price_with_vat),
          work_id: item?.work_id,
          reference: `${item.data_supplier_reference} ${workTimeDescription}`,
          cart_quantity: item.cart_quantity
        };
      });

      setTotalPartsPrice(response.total_price ? Number(response.total_price) : 0);
      setTotalQuantity(response.total_quantity ? Number(response.total_quantity) : 0);
      // Actualizează datele local în componentă
      setDataState((prevData) => [...prevData, ...data]); // Adaugă produsele noi la cele existente
      setHasMore(response?.total_pages > page); // Verifică dacă mai sunt produse
      setCurrentPage(page); // Actualizează pagina curentă
      // Actualizează Redux cu noile date
      dispatch(setCartTotalPagesSlice(response?.total_pages));
      dispatch(setCartCurentPageSlice(page));
      dispatch(setCartSliceQuantityToolbar(response.total_quantity ? Number(response.total_quantity) : 0));
      dispatch(setCartSliceTotalPrice(response.total_price ? Number(response.total_price) : 0));

    } catch (error) {
      console.error(error);
      setLoadingData(false);
      notify('Error');
      setDataState([]);

    }
  };

  useEffect(() => {
    if (props.isActive && containerRef.current) {
      containerRef.current.scrollTop = 0; // Resetează scroll-ul la început
    }
  }, [props.isActive]);

  return (
    <div className={`cart-sliding-v2  ${props.isActive ? 'show-sliding' : 'hidden-sliding'}`}>

      <div className={`cart-sliding-body  ${props.isActive ? 'show-sliding-body' : 'hidden-sliding-body'} ${loadingData || !dataState.length ? 'fixed-position' : ''}`}>

        <div className="cart-sliding-container" >
          {loadingData && dataState.length == 0 ?
            <Spinner class="small" />
            : dataState.length ? (
              <React.Fragment>
                <div className="cart-title">
                  <p >{t('_your_shopping_carts')}</p>
                </div>
                <div className="cart-content">
                  <div className="table" ref={containerRef}>
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th> {t('_reference')}</th>
                          {/* <th> {t('_category')}</th> */}
                          <th> {t('_stock_quantity')}</th>
                          <th style={{ width: '8rem' }}> {t('_price')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataState.map((item, index) => {
                          return (
                            <tr key={item.reference + index} >
                              <td><img src={item.image} alt="" className="img" /></td>
                              <td dangerouslySetInnerHTML={{ __html: String(item.reference) }}></td>
                              {/* <td> {item.categories}  </td> */}
                              <td> {item.cart_quantity}</td>
                              <td>	 {item.price} €</td>
                            </tr>);
                        })
                        }

                      </tbody>

                    </table>
                  </div>
                  <div className="table-spinner">
                    {loadingData && dataState.length ? <Spinner class="extra-small" /> : null}
                  </div>


                  <div className="tfooter">


                    <div>{t('_total')} : {totalQuantity}</div>
                    <div> {Number(totalPartsPrice).toFixed(2)} €</div>

                  </div>
                  <div className="btn">
                    <DefaultButton text={<React.Fragment><FontAwesomeIcon icon={faCartShopping} className="cart-icon" /> {t('_go_to_cart')}</React.Fragment>} onClick={() => handleCartButtonClick()} />
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div className="empty_cart">
                <div className="empty_cart_image">
                  <img src={require('assets/images/empty_cart.png')} alt="empty_cart" />
                </div>
                <div className="empty_cart_title">
                  {t('_your_shopping_carts_empty')}
                </div>
              </div>
            )
          }
        </div>
      </div>
    </div >
  );
};

CartSlidingV2.displayName = 'CartSlidingV2';

export default CartSlidingV2;
