import { FieldErrors, FieldValues, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import DefaultButton from '../../components/buttons/DefaultButton/DefaultButton';
import TextInput from '../../components/inputs/TextInput/TextInput';
import PasswordInput from 'components/inputs/PasswordInput/PasswordInput';
import Spinner from 'components/spinner/Spinner';

import { ILoginForm } from 'models/ILoginForm.model';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../assets/images/logo/logo-ereliable-124x17.svg';

import './NewLogin.scss';
import { useNavigate } from 'react-router-dom';
import MiniDropdown from 'components/dropdowns/MiniDropdown/MiniDropdown';
import { getCountryFromLocale, getLanguageFromLocale, getLanguageFromLocalStorage } from 'utils/locale';
import { IDropdownModel } from 'components/dropdowns/Dropdown.model';
import { languages } from 'utils/constants/locales';

type Props = {
  onSubmit: () => void;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<ILoginForm>;
  isLoading: boolean;
};

const NewLoginView: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();


  const [selectedLanguage, setSelectedLanguage] = useState<IDropdownModel>(getLanguageFromLocalStorage() ?? languages[0]);

  useEffect(() => {
    const language = getLanguageFromLocale(i18n);

    if (language) {
      setSelectedLanguage(language);
    }
  }, [i18n.language]);

  const changeLanguage = (item: IDropdownModel) => {
    const country = getCountryFromLocale(i18n);

    if (!country) {
      return;
    }

    const locale = `${item.id}-${country.id}`;

    localStorage.setItem('locale', locale);
    i18n.changeLanguage(locale);
  };

  return (
    <div className="wrapper-content-login">
      <div className="content">
        <img src={logo} alt="e-reliable" className="logo" />
        <p className="description">
          <Trans
            i18nKey="_app_description"
            components={{ b: <b />, br: <br /> }}
          />
        </p>
        <div className="formWrapper">
          <p>{t('_log_in')}</p>
          <form
            onSubmit={props.handleSubmit(props.onSubmit)}
            className="inputs"
          // autoComplete="nope"
          >
            <label htmlFor="#email" className={`${props.errors.email && 'error'}`}>
              {t('_identifier')}
              <TextInput
                placeholder="distributeur@mail.com"
                {...props.register('email', {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'email_invalid',
                  },
                })}
              // autoComplete="off"
              ></TextInput>
              {props.errors.email?.type === 'pattern' && <p className="error-message">{t('EMAIL_INVALID')}</p>}
              {props.errors.email?.type === 'required' && <p className="error-message">{t('EMAIL_REQUIRED')}</p>}
            </label>
            <label htmlFor="#password" className={`${props.errors.password && 'error'}`}>
              {t('_password')}{' '}
              <PasswordInput autoComplete="new-password" placeholder="* * * * * *" name="password" id="password" register={props.register} required></PasswordInput>
              {props.errors.password?.type === 'required' && <p className="error-message">{t('PASSWORD_REQUIRED')}</p>}
              <p onClick={() => navigate('/forgot-password')}>{t('_forgot_password')}?</p>
            </label>
            <DefaultButton
              text={
                <span>
                  {props.isLoading ? (
                    <Spinner class="Spinner"></Spinner>
                  ) : (
                    <FontAwesomeIcon className="checkIcon" icon={faCheck}></FontAwesomeIcon>
                  )}
                  {t('_confirm')}
                </span>
              }
              disabled={props.isLoading}
              type="submit"
            ></DefaultButton>
          </form>
        </div>
      </div>
      <div className="footer">
        <img src={logo} alt="" />
        <div className="footerContent">
          <span>© 2022 Amerigo international</span>
          <span>{t('_legal_notice')}</span>
          <span>{t('_privacy_policy')}</span>
        </div>
        <div className="language-container">
          <div className="label">{t('_language')}</div>
          <MiniDropdown openUp onClick={changeLanguage} content={selectedLanguage?.id} data={languages} />
        </div>
      </div>
    </div>
  );

};

NewLoginView.displayName = 'NewLoginView';

export default NewLoginView;
