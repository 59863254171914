
import React, { useEffect, useState } from 'react';
import './ItemSlider.scss';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import CURRENCY from 'utils/constants/currency';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import Modal from 'components/modal/Modal';
import ItemViewModal from 'pages/CatalogVehicle/Catalogs/ItemViewModal/ItemViewModal';

type Props = {
  item

};

const ItemSlider: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [showPrice, setShowPrice] = useState(false);
  const { loadingPriceCheckedStatus } = useSelector((state: RootState) => state.buttonPriceCheckedStatus);
  const user = JSON.parse(localStorage.getItem('userData')!);
  const [openModal, setOpenModal] = useState(false);

  function getPrice() {
    if (props.item.price?.price || props.item.price?.price == 0) {
      return props.item.price.price;
    }

    return props.item.price;
  }

  useEffect(() => {
    setShowPrice(!loadingPriceCheckedStatus);
  }, [loadingPriceCheckedStatus]);

  return (<div className="item-container-top-offert">
    <div className="reference">{props.item.data_supplier_reference}</div>
    <div className="item-shop">
      <div className="item-column-1" >

        <div className="img">
          <img
            src={props.item.image || props.item.data_supplier_logo || '/images/car-part-placeholder.png'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder-big.png';
            }}
          />
        </div>
        <div className="product-name">
          {props.item?.data_supplier?.name}
        </div>
      </div>
      <div className="item-column-2" >
        <div className="description">

          {props.item.short_description ? <span> {props.item.short_description || ''}</span> : null}

          <span> {props.item.description} </span>

        </div>

      </div>
      <div className="item-column-3" >
        <div className="explosion">
          <span>{showPrice ? getPrice() ? <div className="oem-price_per_unit"> {getPrice()} {CURRENCY[user.currency]}  </div> : '-' : '-'}</span>
        </div>
        <div className="btn" onClick={() => setOpenModal(true)}>
          <DefaultButton text={t('_buy')} />
        </div>
      </div>

    </div >
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      shouldCloseOnOverlayClick={false}
      childrenComponent={<ItemViewModal setOpenModal={setOpenModal} data={props.item} />}
    />
  </div >);
};

export default ItemSlider;
