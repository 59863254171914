import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './ButtonLink.scss';
import { getMarketplaceBaseUrl } from 'utils/marketplace';

type Props = {
  text: string | string[];
  className?: string;
  pdf_name?: string;
  showPdf?: boolean;
  selected?: boolean;
  image?;
  onClick?: () => void;
  itemId?: string;
  disabled?: boolean
  countItem?: number
};

const ButtonLinkView: React.FC<Props> = (props: Props) => {
  let buttonImage = props.image;

  const renderIcon = (icon: string): JSX.Element => {
    const Icon = icon;

    return <Icon />;

  };

  if (props.image && typeof props.image === 'string' && props.image.startsWith('/images/')) {
    buttonImage = getMarketplaceBaseUrl() + props.image;
  }

  return props.showPdf ? (
    <a
      href={`/flipbook?pdf_name=${props.pdf_name}&name=${props.text}`}
      target="_blank"
      className={`container-link ${props.className || ''}`.trim()}
      rel="noreferrer"
    >
      <img src={`${buttonImage}`} id="link" className="image" onClick={props.onClick} />
      <p className="text" onClick={props.onClick}>
        {props.text}
      </p>
      <FontAwesomeIcon className="pdf-icon" icon={faFilePdf} />
    </a>
  ) : (
    <div
      className={`container-link ${props.className || ''} ${props.selected ? 'selected' : ''} ${props.disabled ? 'disabled' : ''}`.trim()}
      tabIndex={1}
      onClick={props.onClick}
    >
      {buttonImage ? (

        typeof props.image === 'string' ? (
          <img
            src={buttonImage}
            id="link"
            className="image"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping

              currentTarget.src = '/images/car-part-placeholder.png';
            }}
            onClick={props.onClick}
          />
        ) : (
          props.image && <div className="image-svg" onClick={props.onClick}> {renderIcon(props.image)} </div>
        )


      ) : (
        <div className="image"></div>
      )}
      <p className="text">{props.text}</p>
      {
        props.countItem ? <div className="number">{props.countItem}</div> : null
      }
    </div>
  );
};


ButtonLinkView.displayName = 'ButtonLinkView';

export default ButtonLinkView;

