import React, { useState, useRef, useEffect } from 'react';
import Tesseract from 'tesseract.js';

import Spinner from 'components/spinner/Spinner';
import './TextRecognition.scss';
import { useTranslation } from 'react-i18next';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faClose } from '@fortawesome/free-solid-svg-icons';

type Props = {
  setRecognizedText: (text: string) => void;
  open: boolean;
  setOpenModal: (e: boolean) => void;
  setSelectedType: (text: string | null) => void;
};

const TextRecognition: React.FC<Props> = (props) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [image, setImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [vinResult, setVinResult] = useState('');
  const { t } = useTranslation();

  // Trigger file input when modal is opened
  useEffect(() => {
    if (props.open && fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [props.open]);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    if (fileInputRef.current) {
      setVinResult('');
      fileInputRef.current.click();
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const preprocessImage = (file: File): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target?.result) {
          img.src = e.target.result as string;
        }
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject(new Error('Failed to get canvas context'));

          return;
        }

        canvas.width = img.width;
        canvas.height = img.height;

        // Draw image on canvas
        ctx.drawImage(img, 0, 0);

        // Desenăm imaginea pe canvas
        ctx.drawImage(img, 0, 0);

        // Obținem datele imaginii din canvas
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;

        const threshold = 110; // Pragul de binarizare

        // Binarizarea imaginii: pixelele sub prag devin negre, celelalte devin albe
        for (let i = 0; i < data.length; i += 4) {
          const avg = (data[i] + data[i + 1] + data[i + 2]) / 3; // Media valorilor RGB ale fiecărui pixel
          const color = avg < threshold ? 0 : 255;  // Negru sau alb

          data[i] = color;     // Canalul roșu
          data[i + 1] = color; // Canalul verde
          data[i + 2] = color; // Canalul albastru
        }


        ctx.putImageData(imageData, 0, 0);
        resolve(canvas);
      };

      img.onerror = reject;
    });
  };


  useEffect(() => {
    const recognizeText = async () => {
      if (selectedImage) {
        setLoading(true);

        try {
          // Preprocess image
          const canvas = await preprocessImage(selectedImage);
          const processedImage = canvas.toDataURL('image/jpeg');

          setImage(processedImage);
          // Perform OCR
          const result = await Tesseract.recognize(
            processedImage,
            'eng',
            {
              logger: (m) => {
                if (m.status === 'recognizing text') {
                  setLoading(true);
                }
              }
            }
          );


          console.log(result.data.text);

          const vin = extractVIN(result.data.text);

          setVinResult(vin);
        } catch (error) {
          console.error('Error during text recognition:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    recognizeText();
  }, [selectedImage]);

  const extractVIN = (text: string): string => {
    const regex = /[a-zA-Z0-9]{17}/;
    const matchResult = text.match(regex);

    if (!matchResult) { return ''; }

    const vin = matchResult[0].toUpperCase();

    // Verificare suplimentară a VIN-ului
    if (vin.length !== 17 || !/[A-Z]/.test(vin) || !/\d/.test(vin)) { return ''; }

    // Înlocuire caractere invalide
    const cleanedVin = vin.replace(/I/g, '1')
      .replace(/O/g, '0')
      .replace(/Q/g, '0');

    // Verificare finală pentru caracterele de tip spațiu
    return cleanedVin.includes(' ') ? '' : cleanedVin;
  };

  const handleSubmitClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    props.setRecognizedText(vinResult);
    props.setOpenModal(false);
  };

  return (
    <div className="text-recognition">
      <div className="back-modal" onClick={() => { props.setSelectedType(null); }}><FontAwesomeIcon icon={faArrowLeft} /> {t('_go_back')}</div>
      <div className="close-modal" onClick={() => { props.setOpenModal(false); }}><FontAwesomeIcon icon={faClose} /></div>
      <div className="tile-recognition">
        {t('_local_image')}
      </div>

      <div className="body-recognition">
        <div className="selected-image">
          {loading ? (
            <Spinner class="small" />
          ) : (
            <React.Fragment>
              <img
                src={selectedImage ? URL.createObjectURL(selectedImage) : '/images/car-part-placeholder.png'}
                alt="Selected"
                className="image-preview"
              />
              <img
                src={image ? image : '/images/car-part-placeholder.png'}
                alt="Selected"
                className="image-preview"
              />
              <div className="get_image">
                < DefaultButton text={t('_get_image')} onClick={handleIconClick} />
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="div-input">
          <label className="div-input-label">
            {t('_vin')}
          </label>
          <input
            type="text"
            defaultValue={vinResult}
            className="url-input"
          />
        </div>
        <div className="get_vin">
          <DefaultButton text={t('_get_vin')} onClick={handleSubmitClick} />
        </div>
      </div>

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: 'none' }}
      />
    </div >
  );
};

export default TextRecognition;
