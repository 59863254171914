import { IItemClick } from 'models/catalog.model';
import React from 'react';
import ButtonLink from '../buttons/ButtonLink/ButtonLink';
import './List.scss';

type Props = {
  title: string;
  content;
  images?;
  className?: string;
  itemClassName?: string;
  onItemClick?: (item: IItemClick) => void;
  showPdf?: boolean;
  activeCategory?;
  itemId?: string;
};

const ListView: React.FC<Props> = (props: Props) => {
  return (
    <div className="list-container">
      {props.title && <h1 className={`heading-list ${props.className ? props.className : ''}`}>{props.title}</h1>}
      {props.content &&
        props.content.map((item, index: number) => {
          return (
            <ButtonLink
              text={typeof item === 'object' ? item.name : item}
              disabled={item.number_of_products === 0}
              key={index}
              onClick={() => props.onItemClick?.(item)}
              className={`check ${props.itemClassName}`}
              showPdf={props.showPdf}
              image={props.images ? props.images[index] : undefined}
              selected={props.itemId ? props.activeCategory?.[props.itemId] === (typeof item === 'object' ? item[props.itemId] : 0) : false}
              countItem={item?.number_of_products || null}
            ></ButtonLink>
          );
        })}
    </div>
  );
};

ListView.displayName = 'ListView';

export default ListView;
