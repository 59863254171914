import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './TopSalesNavbar.scss';
import SortBy from 'components/SortBy/SortBy';


type Props = {
  title: string;
  option?: ReactElement
  input?: ReactElement,
  showSortBy?: boolean
}
const TopSalesNavbar: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className="top-sales-navbar-container">
      <div className="breadcrumbs">
        {t(props.title)}
      </div>

    </div >
  );
};


export default TopSalesNavbar;