import React, { useState } from 'react';

import IconButton from 'components/buttons/IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './VinWebCam.scss';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import Modal from 'components/modal/Modal';
import ModalWebCam from './ModalWebCam/ModalWebCam';

type Props = {
  setRecognizedText: (text: string) => void;
};


const VinWebCam: React.FC<Props> = (props) => {

  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenModal(true);

  };

  return (
    <div className="vin-web-cam">

      <IconButton
        className="iconButton"
        onClick={handleIconClick}
        icon={<FontAwesomeIcon icon={faCamera} className="faImage" />}
      />
      <Modal
        openModal={openModal}
        setOpenModal={setOpenModal}
        // shouldCloseOnOverlayClick={false}
        childrenComponent={
          <ModalWebCam setRecognizedText={props.setRecognizedText} setOpenModal={setOpenModal} />
        }
      />

    </div>
  );
};

export default VinWebCam;
