import {

  IFilterForm,
  IPromise,

} from 'interfaces/selectClient';
import { serverAxios } from '../../http';

class ManagerGroupSelectClinetHttp {
  private static clients = 'automotor-client-group-manager/clients';
  private static saveClient = 'automotor-client-group-manager/clients/';


  public async getClients(param: IFilterForm): Promise<IPromise> {
    return serverAxios.get(ManagerGroupSelectClinetHttp.clients, { params: param });
  }

  public async saveClient(id: number): Promise<void> {
    return serverAxios.post(ManagerGroupSelectClinetHttp.saveClient + id);
  }


  // public async zoneCommercials(): Promise<IPromiseZoneCommercials> {
  //   return serverAxios.get(SelectClinetHttp.zoneCommercials);
  // }
  // public async countries(): Promise<IPromiseCountries> {
  //   return serverAxios.get(SelectClinetHttp.countries);
  // }
  // public async saveClientForSeller(id: number): Promise<IPromiseCountries> {
  //   return serverAxios.post(SelectClinetHttp.saveClientForSeller + id);
  // }

  // public async clientInfo(id: number): Promise<IHttpResponse<IClientInfo>> {
  //   return serverAxios.get(SelectClinetHttp.clientInfo + id);
  // }
  // public async supplierMapping(): Promise<IHttpResponse<ISupplierMapping[]>> {
  //   return serverAxios.get(SelectClinetHttp.supplierMapping);
  // }
  // public async teccomMapping(id: number): Promise<IHttpResponse<ITecomMapping[]>> {
  //   return serverAxios.get(SelectClinetHttp.teccomMapping + id);
  // }

  // public async supplierTest(id: number, data: ITestForm): Promise<IHttpResponse<ITestResponce>> {
  //   return serverAxios.post('amerigo/supplier-amerigo/test/' + id + '/teccom-mapping', data);
  // }
  // public async supplierUpdate(id: number, data: IUpdateForm[]): Promise<IHttpResponse<ITecomMapping[]>> {
  //   return serverAxios.patch('amerigo/supplier-amerigo/clients/' + id + '/teccom-mapping', data);
  // }
  // public async getCatalogInfo(): Promise<IHttpResponse<ICatalogInfo>> {
  //   return serverAxios.get(SelectClinetHttp.catalogInfo);
  // }
  // public async getSellerClients(): Promise<IOption[]> {
  //   const { data } = await serverAxios.get(SelectClinetHttp.sellerClients);

  //   return data;
  // }
  // public async uploadSellerFiles(data: FormData): Promise<[]> {
  //   return serverAxios.post(SelectClinetHttp.uploadSellerFiles, data, { headers: { 'content-type': 'multipart/form-data' } });
  // }

  // public async importCatalogOil(data: FormData): Promise<IHttpResponse<[]>> {
  //   return serverAxios.post(SelectClinetHttp.importCatalogOil, data, { headers: { 'content-type': 'multipart/form-data' } });
  // }
  // public async importCatalogConsumable(data: FormData): Promise<IHttpResponse<[]>> {
  //   return serverAxios.post(SelectClinetHttp.importCatalogConsumable, data, { headers: { 'content-type': 'multipart/form-data' } });
  // }
  // public async importCatalogTyre(data: FormData): Promise<IHttpResponse<[]>> {
  //   return serverAxios.post(SelectClinetHttp.importCatalogTyre, data, { headers: { 'content-type': 'multipart/form-data' } });
  // }
}

export default ManagerGroupSelectClinetHttp;
