import React, { PropsWithChildren } from 'react';
import './ProductCategoryItemNew.scss';

type Props = {
  image
  mainImage?
  text: string;
  onClick: () => void;
};

const ProductCategoryItemNew: React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const renderIcon = (icon: string): JSX.Element => {
    const Icon = icon;

    return <Icon />;

  };

  return (
    <div className="product-category-item-new" onClick={props.onClick}>
      {props.mainImage && <div className="item-main-image">
        {typeof props.mainImage === 'string' ? (
          <img className="icon" src={props.mainImage} alt="image" />
        ) : (
          props.mainImage && <span> {renderIcon(props.mainImage)} </span>
        )}
      </div>}
      <div className="item-button">
        <div className="item-icon">
          {typeof props.image === 'string' ? (
            <img className="icon" src={props.image} alt="image" />
          ) : (
            props.image && <span> {renderIcon(props.image)} </span>
          )}
        </div>
        <div className="item-text">
          <p>{props.text}</p>
        </div>
      </div>
    </div >
  );
};

ProductCategoryItemNew.displayName = 'ProductCategoryItemNew';

export default ProductCategoryItemNew;
