import { createSlice } from '@reduxjs/toolkit';
import { CartTableItem } from 'applications/retailer/views/Cart/CartTable/CartTable.model';


type ICartState = {
  cart: CartTableItem[];
  quantityToolbarSlice: number;
  totalPriceSlice: number;
  totalPagesSlice: number;
  curentPageSlice: number;

};

const initialState: ICartState = {
  cart: [],
  quantityToolbarSlice: 0,
  totalPriceSlice: 0,
  totalPagesSlice: 0,
  curentPageSlice: 0
};

const cartSliceSlice = createSlice({
  name: 'cart-v2',
  initialState,
  reducers: {
    setCartSliceQuantityToolbar(state, { payload }) {
      state.quantityToolbarSlice = payload;
    },

    setCartSliceTotalPrice(state, { payload }) {
      state.totalPriceSlice = payload;
    },
    setCartSlice(state, { payload }) {
      state.cart = payload;
    },
    setCartCurentPageSlice(state, { payload }) {
      state.curentPageSlice = payload;
    },

    setCartTotalPagesSlice(state, { payload }) {
      state.totalPagesSlice = payload;
    },
    removeCartSlice: (state) => {
      state.cart = [];
      state.quantityToolbarSlice = 0;
      state.totalPriceSlice = 0;
      state.totalPagesSlice = 0;
      state.curentPageSlice = 0;
    },

  }
});

export const { setCartTotalPagesSlice, setCartCurentPageSlice, setCartSlice, setCartSliceQuantityToolbar, setCartSliceTotalPrice, removeCartSlice } = cartSliceSlice.actions;

export default cartSliceSlice.reducer;
