
import { serverAxios, addRouteParam, IHttpResponse } from '../../http';

class NewsHttp {
  private static route = '/landing/news';
  private static order = '/landing/news/:id';

  public async getNews(params: {}): Promise<IHttpResponse<INewsData>> {
    return serverAxios.get(NewsHttp.route, { params });
  }
  public async getNewsById(id: string): Promise<IHttpResponse<INews>> {
    return serverAxios.get(addRouteParam(NewsHttp.order, { id }));
  }
}

export interface INews {
  content: string;
  created_at: string;
  id: number;
  image: string;
  short_title: string;
  status: boolean;
  title: string;
  video: string;
}

export interface INewsData {
  data: INews[];
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
}

export default NewsHttp;
