import { addRouteParam, IHttpResponse, serverAxios } from '../../http';
import { IBodyResponseOil, IOil } from 'interfaces/oil';
import { CancelToken } from 'axios';

class OilsCatalogHttp {
  private static ROUTE = 'oil/products';
  private static VIEW_ROUTE = 'oil/products/:id';
  private static FILTER_ROUTE = 'oil/filters';

  public async getOils(urlParams: IOilCatalogParams, cancelToken?: CancelToken): Promise<IHttpResponse<IBodyResponseOil>> {
    return serverAxios.get(OilsCatalogHttp.ROUTE, { params: urlParams, cancelToken });
  }
  public async getOil(id: string): Promise<IHttpResponse<IOil>> {
    return serverAxios.get(addRouteParam(OilsCatalogHttp.VIEW_ROUTE, { id }));
  }
  public async getOilFilters(urlParams: IOilCatalogParams): Promise<IHttpResponse<IOilCatalogFilter>> {
    return serverAxios.get(OilsCatalogHttp.FILTER_ROUTE, { params: urlParams });
  }
}

export interface IOilCatalogParams {
  vin?: string
  source?: string
  vehicle_identifier?: string
  vehicle_id?: string
  page?: number
  category_ids?: string[]
}

export interface IOilCatalogFilter {
  brand: IFilterResponse[]
  category: IFilterResponse[]
  family: IFilterResponse[]
  quantity: IFilterResponse[]
  viscosity: IFilterResponse[]
}

export interface IFilterResponse {
  key: string
  value: string
}

export default OilsCatalogHttp;
