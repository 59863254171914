import React, { useEffect, useState } from 'react';

import './PartsTopOfferSlider.scss';
import useHttp from 'utils/hooks/useHttp';
import { IProductTopOffer } from 'utils/hooks/http/retailer.http';
import { ReactComponent as PartsNotFoundImage } from 'assets/images/parts-not-found.svg';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'lodash';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import ItemSlider from './ItemSlider/ItemSlider';

type Props = {};

const PartsTopOfferSlider: React.FC<Props> = () => {
  const http = useHttp();
  const { t } = useTranslation();

  const [dataState, setDataState] = useState<IProductTopOffer[]>([]);
  const { isMobile, isTablet } = useDeviceDetect();

  useEffect(() => {
    http.retailerHttp.getProductTopOffer().then((res) => {
      setDataState(res.data);
    });
  }, []);


  const getProductTopOffer = () => {
    return Object.keys(dataState).map((key) => {
      return dataState[key].map((item, index) => {
        if (key == 'tyre') {
          return <ItemSlider item={item} key={index} />;
        }

        if (key == 'consumable') {
          return <ItemSlider item={item} key={index} />;
        }

        if (key == 'oil') {
          return <ItemSlider item={item} key={index} />;
        }

        if (key == 'battery') {
          return <ItemSlider item={item} key={index} />;
        }
      });
    });
  };


  return (
    <div className={`container-top-offer  ${Object.values(dataState).some((value) => Array.isArray(value) && value.length !== 0) ? 'container-top-offer-show' : 'container-top-offe-hide'}`} >
      <div className="top-offer-title">
        <h1>{upperCase(t('_top_offer'))}</h1>
      </div>

      {
        Object.values(dataState).some((value) => Array.isArray(value) && value.length !== 0) ?
          <div className="container-top-body" >

            <SwiperSlider
              slidesPerView="auto"
              navigation={!(isMobile || isTablet) && getProductTopOffer().length != 1}
              pagination={isMobile || isTablet}
              loop={isMobile || isTablet}
            //autoplay
            >
              {
                getProductTopOffer().map((items, index: number) => {
                  return items.map((item, i: number) => {
                    return (
                      <SwiperSlide className="swiper-slide" key={index + i}>
                        {item}
                      </SwiperSlide>
                    );
                  }
                  );
                })
              }
            </SwiperSlider>
          </div>
          :
          <div className="no-parts-data-fex">
            <div className="notFoundSvg">
              <PartsNotFoundImage />
            </div>
            <h1>{t('_parts_not_found')}</h1>
          </div>
      }

    </div >
  );
};

PartsTopOfferSlider.displayName = 'PartsTopOfferSlider';

export default PartsTopOfferSlider;
