import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useHttp from 'utils/hooks/useHttp';
import Spinner from 'components/spinner/Spinner';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import { notify } from 'utils/marketplace';
import i18n from 'i18n';
import { useLocaleDatePicker } from 'utils/localeDatePicker';
import Select from 'react-select';
import { IFilterValue } from 'interfaces/tyre';
import 'react-datepicker/dist/react-datepicker.css';
import './CreateAllowedList.scss';
import TextInput from 'components/inputs/TextInput/TextInput';
import { FileWithPath, useDropzone } from 'react-dropzone';
import TextareaInput from 'components/inputs/TextareaInput/TextareaInput';

type Props = {
  setOpenModal: (e: boolean) => void;
  setSubmit: (e: boolean) => void;
  clients;
  providers;
  countries;
  commercialZones;
  loading: boolean;
};

type IForm = {
  numcli: IFilterValue[] | null;
  codfou: IFilterValue;
  title: string;
  comment: string;
  type_form: IFilterValue;
};

const CreateAllowedList: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const http = useHttp();
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const { isMobile } = useDeviceDetect();
  const [invalidFile, setInvalidFile] = useState(false);
  const [file, setFile] = useState<FileWithPath | null>(null);

  const validate = () => {
    return Yup.object().shape({
      numcli: Yup.array()
        .required(t('_fild_required'))
        .of(
          Yup.object().shape({
            label: Yup.string().required(t('_fild_required')),
            value: Yup.string().required(t('_fild_required')),
          }),
        ),
      title: Yup.string().required(t('_fild_required')),
      codfou: Yup.object().shape({
        label: Yup.string().required(t('_fild_required')),
        value: Yup.string().required(t('_fild_required')),
      }),
    });
  };

  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    control,
    watch,
    resetField,
    formState: { errors },
  } = useForm<IForm>({ resolver: yupResolver(validate()) });

  useLocaleDatePicker(i18n.language);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      setInvalidFile(false);
    },
    [file],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.xls', '.xlsx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
  });

  const onSubmit = async () => {
    if (!file) {
      setInvalidFile(true);

      return;
    } else {
      setInvalidFile(false);
    }

    const values = getValues();

    setLoadingSave(true);
    const formData: FormData = new FormData();

    if (watch('type_form.value') === '1') {
      const clients = values.numcli;

      clients?.forEach(({ value }) => {
        formData.append('numcli[]', String(value));
      });
    }

    if (watch('type_form.value') === '2') {
      const ids: Array<string> = [];

      props.clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.label === client.country) {
            ids.push(client.value);
          }
        });
      });

      if (ids.length == 0) {
        notify(t('_error'), 'error', t('_not_have_client_for') + ' ' + values?.numcli?.[0].label);
        setLoadingSave(false);

        return;
      }

      ids.forEach((id) => {
        formData.append('numcli[]', id);
      });
    }

    if (watch('type_form.value') === '3') {
      const ids: Array<string> = [];

      props.clients.forEach((client) => {
        values.numcli?.forEach((item) => {
          if (item.value === client.zone_commercial) {
            ids.push(client.value);
          }
        });
      });

      if (ids.length == 0) {
        notify(t('_not_have_client_for') + ' ' + values?.numcli?.[0].label);
        setLoadingSave(false);

        return;
      }

      ids.forEach((id) => {
        formData.append('numcli[]', id);
      });
    }

    formData.append('title', values.title);
    formData.append('comments', values.comment);
    formData.append('codfou', String(values.codfou.value));

    if (file) {
      formData.append('file', file);
    }

    http.allowedList
      .saveAllowedPart(formData)
      .then(() => {
        notify(t('_created_successfully'), 'success', t('_success'));
        props.setOpenModal(false);
        props.setSubmit(true);
        setLoadingSave(false);
      })
      .catch((error) => {
        notify(error?.message ?? error);
        setLoadingSave(false);
      });
  };

  const customStyles = (hasError) => ({
    control: (styles) => ({
      ...styles,
      ...(hasError ? { borderColor: 'red' } : { border: 'none' }),
    }),
  });

  useEffect(() => {
    setValue('type_form', { value: '1', label: String(t('_client')) });
  }, []);

  return (
    <div className="create-allowed-list-container">
      <div
        className="close-modal"
        onClick={() => {
          props.setOpenModal(false);
        }}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
      <div className="title">{t('_create_allowed_list')}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="modal-info">
          <div className="row">
            <div className="container">
              <label>{t('_title')}</label>
              <div className="input-item">
                <TextInput {...register('title')} placeholder={t('_title')} />
                <p className="error">{errors.title?.type == 'required' ? errors.title?.message : ''}</p>
              </div>
            </div>
            <div className="container">
              <label>{t('_provider')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="codfou"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                        }}
                        options={props.providers}
                        placeholder={t('_provider')}
                        styles={customStyles(errors.codfou?.label?.type)}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
                <p className="error">{errors.codfou?.label?.type == 'required' ? errors.codfou?.label?.message : ''}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`allowed_list-file-upload-container ${invalidFile && 'invalid'}`}>
              <i className="fas fa-folder-open allowed_list-folder-upload" />
              <div {...getRootProps()} className="allowed_list-title-upload ">
                {file ? (
                  <div>{file.name}</div>
                ) : (
                  <React.Fragment>
                    {t('_drag_drop_here')}
                    <br />
                    <strong className="browse-files" >
                      {t('_csv_browse')}
                    </strong>
                  </React.Fragment>
                )}
              </div>
              <input
                {...getInputProps()}
                accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                type="file"
                style={{ display: 'none' }}
              />
            </div>
          </div>
          <div className="download-template">
            <a onClick={(e) => e.stopPropagation()} href="/csv-template/test_reference.xlsx" download><FontAwesomeIcon icon={faDownload} />{t('_download_template')}</a>
          </div>
          <div className="row">
            <div className="container">
              <label>{t('_select_by')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="type_form"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);
                          setValue('numcli', null);
                          resetField('numcli');
                        }}
                        options={[
                          {
                            value: '1',
                            label: String(t('_client')),
                          },
                          {
                            value: '2',
                            label: String(t('_country')),
                          },
                          {
                            value: '3',
                            label: String(t('_zone_commercial')),
                          },
                        ]}
                        isSearchable={!isMobile}
                      />
                    );
                  }}
                />
              </div>
            </div>

            <div className={`container ${watch()?.type_form?.value === '2' ? '' : 'hide'}`}>
              <label>{t('_select_by')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="numcli"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);

                          if (val.length == 0) {
                            resetField('numcli');
                          }
                        }}
                        options={props.countries}
                        placeholder={t('_clients_country')}
                        styles={customStyles(errors.numcli?.type)}
                        isSearchable={!isMobile}
                        isMulti
                      />
                    );
                  }}
                />
                <p className="error">{errors.numcli?.type == 'required' ? errors.numcli?.message : ''}</p>
              </div>
            </div>


            <div className={`container ${watch()?.type_form?.value === '1' ? '' : 'hide'}`}>
              <label>{t('_client')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="numcli"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);

                          if (val.length == 0) {
                            resetField('numcli');
                          }
                        }}
                        options={props.clients}
                        placeholder={t('_client')}
                        styles={customStyles(errors.numcli?.type)}
                        isSearchable={!isMobile}
                        isMulti
                      />
                    );
                  }}
                />
                <p className="error">{errors.numcli?.type == 'required' ? errors.numcli?.message : ''}</p>
              </div>
            </div>
            <div className={`container ${watch()?.type_form?.value === '3' ? '' : 'hide'}`}>
              <label>{t('_zone_commercial')}</label>
              <div className="input-item">
                <Controller
                  control={control}
                  name="numcli"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        value={value}
                        className="select-dropdown"
                        onChange={(val) => {
                          onChange(val);

                          if (val.length == 0) {
                            resetField('numcli');
                          }
                        }}
                        options={props.commercialZones}
                        placeholder={t('_zone_commercial')}
                        styles={customStyles(errors.numcli?.type)}
                        isSearchable={!isMobile}
                        isMulti
                      />
                    );
                  }}
                />
                <p className="error">{errors.numcli?.type == 'required' ? errors.numcli?.message : ''}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="container">
              <label>{t('_comment')}</label>
              <div className="input-item">
                <TextareaInput {...register('comment')} placeholder={t('_comment')} noResize rows={4} />
                <p className="error">{errors.comment?.type == 'required' ? errors.comment?.message : ''}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="btn-save">
          <button type="submit">
            {loadingSave ? <Spinner class="extra-small" /> : null} <div className="title-btn-save">{t('_save')}</div>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAllowedList;
