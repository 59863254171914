import { IBodyResponseBatteryCategories } from 'interfaces/battery';
import { ICatalogEdit, ICatalogInfo, ICatalogInfoTranslate, ICatalogs, ICatalogSupplierMappingGroup } from 'models/catalog.model';
import {
  IBatteryBrands,
  IBatteryCategory,
  ICategory,
  IConsumableBrands,
  IConsumableCategories,
  IOilCategory,
  ITyreCategory,
  IVehicleTyresFilter,
  IVehicleTyresFilterResponce
} from 'views/Sidebar/Sidebar.model';
import { addRouteParam, IHttpResponse, serverAxios } from '../../http';
import { CancelToken } from 'axios';
import { IParamsTyre } from 'interfaces/tyre';


class CatalogsHttp {
  private static route = '/catalogs';
  private static catalogSupplierMappingGroup = '/catalogs/supplier-mapping-groups';
  private static catalogInfoRoute = '/catalog/:catalog_id/info';
  private static catalogEdit = '/catalog/:catalog_id/edit';
  private static catalogInfoTranslate = '/catalog/:catalog_id/info-translations';
  private static downloadTariffRoute = '/client-notification/file/:tariff_id';

  private static tyresCategories = '/tyres/quick-filter';
  private static oilCategories = '/oil/categories';
  private static consumableCategories = '/consumable/category/all-list';
  private static carConsumableCategories = '/consumable/categories/products';
  private static carOilCategories = '/oil/categories/products';
  private static carBatteriesCategories = '/battery/categories/products';
  private static carTyresCategories = '/tyres/categories/products';
  private static batteryCategories = '/battery/category/all-list';

  private static consumableBrand = '/consumable/brand';
  private static consumableCategory = '/consumable/category';
  private static categoryWithConsumables = '/consumable/category-consumables';
  private static brandWithConsumables = '/consumable/brand-consumables';

  private static vehicleTyresFilter = '/tyres/vehicle-filter';

  private static batteryBrand = '/battery/brand/list';
  private static batteriesWithBrand = '/battery/brand/list-data';
  private static batteryCategory = '/battery/category/list';
  private static batteriesWithCategories = '/battery/category/list-data';

  private static partsSuppliers = 'retailer/catalog_non_tec_dock/supplier/list';

  public async getCatalogsData(): Promise<ICatalogs[]> {
    const { data } = await serverAxios.get(CatalogsHttp.route);

    return data;
  }

  public async getCatalogSupplierMappingGroup(): Promise<ICatalogSupplierMappingGroup[]> {
    const { data } = await serverAxios.get(CatalogsHttp.catalogSupplierMappingGroup);

    return data;
  }

  public getCatalogInfo(catalog_id: string): Promise<IHttpResponse<ICatalogInfo>> {
    return serverAxios.get(addRouteParam(CatalogsHttp.catalogInfoRoute, { catalog_id }));
  }


  public getCatalogInfoTranslate(catalog_id: string): Promise<IHttpResponse<ICatalogInfoTranslate>> {
    return serverAxios.get(addRouteParam(CatalogsHttp.catalogInfoTranslate, { catalog_id }));
  }
  public getCatalogEdit(catalog_id: string, data: FormData): Promise<IHttpResponse<ICatalogEdit>> {
    return serverAxios.post(addRouteParam(CatalogsHttp.catalogEdit, { catalog_id }), data);
  }

  public downloadTariff(tariff_id: number): Promise<IHttpResponse<Blob>> {
    return serverAxios.get(addRouteParam(CatalogsHttp.downloadTariffRoute, { tariff_id }), {
      responseType: 'blob',
    });
  }

  public async getOilCategories(): Promise<IOilCategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.oilCategories);

    return data;
  }

  public async getCarOilAllCategories(urlParams: ICategory | {}, cancelToken?: CancelToken): Promise<ICategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.carOilCategories, { params: urlParams, cancelToken: cancelToken });

    return data;
  }

  public async getConsumableAllCategories(): Promise<ICategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.consumableCategories);

    return data;
  }

  public async getCarConsumableAllCategories(urlParams: ICategory | {}, cancelToken?: CancelToken): Promise<ICategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.carConsumableCategories, { params: urlParams, cancelToken: cancelToken });

    return data;
  }
  public async getBatteriesAllCategories(): Promise<ICategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.batteryCategories);

    return data;
  }
  public async getCarBatteriesAllCategories(urlParams: IParamsTyre | {}, cancelToken?: CancelToken): Promise<ICategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.carBatteriesCategories, { params: urlParams, cancelToken: cancelToken });

    return data;
  }

  public async getTyreAllCategories(urlParams: IParamsTyre | {}, cancelToken?: CancelToken): Promise<ICategory[]> {
    const { data } = await serverAxios.get(CatalogsHttp.carTyresCategories, { params: urlParams, cancelToken: cancelToken });

    return data;
  }

  public async getTyresCategories(): Promise<ITyreCategory> {
    const { data } = await serverAxios.get(CatalogsHttp.tyresCategories);

    return data;
  }

  public async fetchVehicleTyresCategories(params: IVehicleTyresFilter): Promise<IHttpResponse<IVehicleTyresFilterResponce[]>> {
    return serverAxios.get(CatalogsHttp.vehicleTyresFilter, { params });
  }

  public async getConsumableBrands(urlParams: { per_page: number }): Promise<IHttpResponse<IConsumableBrands[]>> {
    const { data } = await serverAxios.get(CatalogsHttp.consumableBrand, { params: urlParams });

    return data;
  }

  public async getBrandsWithConsumables(): Promise<IHttpResponse<IConsumableBrands[]>> {
    return serverAxios.get(CatalogsHttp.brandWithConsumables);

  }

  public async getConsumableCategories(urlParams: { per_page: number }): Promise<IHttpResponse<IConsumableCategories[]>> {
    const { data } = await serverAxios.get(CatalogsHttp.consumableCategory, { params: urlParams });

    return data;
  }

  public getCategoriesWithConsumables(): Promise<IHttpResponse<IConsumableCategories>> {
    return serverAxios.get(CatalogsHttp.categoryWithConsumables);
  }

  public async getBrandsBattery(urlParams: { per_page: number }): Promise<IHttpResponse<IBatteryBrands[]>> {
    const { data } = await serverAxios.get(CatalogsHttp.batteryBrand, { params: urlParams });

    return data;
  }
  public async getCategory(urlParams: { per_page: number }): Promise<IHttpResponse<IBodyResponseBatteryCategories[]>> {
    const { data } = await serverAxios.get(CatalogsHttp.batteryCategory, { params: urlParams });

    return data;
  }
  public async getPartsSuppliers(urlParams: { per_page: number }): Promise<IHttpResponse<IBodyResponseBatteryCategories[]>> {
    const { data } = await serverAxios.get(CatalogsHttp.partsSuppliers, { params: urlParams });

    return data;
  }

  public async getBrandsWithBatteries(): Promise<IHttpResponse<IBatteryBrands[]>> {
    return serverAxios.get(CatalogsHttp.batteriesWithBrand);
  }

  public async getCategoriesWithBatteries(): Promise<IHttpResponse<IBatteryCategory[]>> {
    return serverAxios.get(CatalogsHttp.batteriesWithCategories);
  }

}

export default CatalogsHttp;
