import { addRouteParam, IHttpResponse, serverAxios } from 'utils/http';
import { IEina, IEinaTechnotes } from './interface/eina.interface';

class EinaHttp {

  private static login = '/eina/auth';
  private static versionTechnotes = '/eina/version/:id/technotes';
  private static versionTechnoteId = '/eina/technotes/:id';
  private static tecdocId = '/eina/ktypnr/:id';
  private static autologin = '/eina/authVTS';

  public async fetchEinaLogin(): Promise<IHttpResponse<[]>> {
    return serverAxios.get(EinaHttp.login);
  }

  public async fetchVersionTechnotes(id: number, params: IEina): Promise<IHttpResponse<IEinaTechnotes[]>> {
    return serverAxios.get(addRouteParam(EinaHttp.versionTechnotes, { id }), { params });
  }

  public async fetchTechnoteId(id: number): Promise<IHttpResponse<IEinaTechnotes[]>> {
    return serverAxios.get(addRouteParam(EinaHttp.versionTechnoteId, { id }));
  }

  public async fetchTecdocId(id: number): Promise<IHttpResponse<string>> {
    return serverAxios.get(addRouteParam(EinaHttp.tecdocId, { id }));
  }

  public async autologinToVTS(): Promise<IHttpResponse<IEinaAutologin>> {
    return serverAxios.get(EinaHttp.autologin);
  }
}

interface IEinaAutologin {
  autologin_url: string;
}

export default EinaHttp;