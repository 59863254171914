import { serverAxios } from '../../http';
import {
  ActiaListRequest,
  IActiaReport,
  IActiaResponseData,
  IActiaSessionRequest
} from 'components/DiagnosticItems/Actia/actia';

class ActiaHttp {
  private static actiaList = '/actia/session/list';

  private static actiaCreateSession = '/actia/session/create';

  private static actiaDeleteSession = '/actia/session/delete/';

  public static downloadReport = '/actia/attachments/';

  public static getErrorCodesByVehicleId = '/actia/error-code/ktypnr/';
  public static getErrorCodesBySessionId = '/actia/error-code/session/';

  public async getActiaList(request: ActiaListRequest): Promise<IActiaResponseData> {
    const { data } = await serverAxios.get(ActiaHttp.actiaList, { params: { ...request } });

    return data;

  }

  public async createSession(request: IActiaSessionRequest): Promise<IActiaResponseData> {
    const { data } = await serverAxios.post(ActiaHttp.actiaCreateSession, request);

    return data;

  }

  public async deleteSession(id: number): Promise<IActiaResponseData> {
    const { data } = await serverAxios.delete(ActiaHttp.actiaDeleteSession + id);

    return data;

  }

  public async downloadReport(uid: string): Promise<IActiaReport> {
    const { data } = await serverAxios.get(ActiaHttp.downloadReport + uid);

    return data;
  }

  public async getErrorCodesByVehicleId(vehicleId: string): Promise<IActiaReport> {
    const { data } = await serverAxios.get(ActiaHttp.getErrorCodesByVehicleId + vehicleId);

    return data;
  }

  public async getErrorCodesBySessionId(uid: string): Promise<IActiaReport> {
    const { data } = await serverAxios.get(ActiaHttp.getErrorCodesBySessionId + uid);

    return data;
  }

}

export default ActiaHttp;
