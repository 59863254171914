
import { IHttpResponse, serverAxios } from '../../http';


class PromotionsHttp {
  private static saveRoute = 'promotion';
  private static listRoute = 'promotions';
  private static deleteRoute = 'promotion/';
  private static activeRoute = 'active-promotions';
  private static infoRoute = 'promotion/';
  private static updateRoute = 'promotion/';
  private static activeAmerigo = 'active-promotions?catalog_id=';

  public savePromotion(data: FormData): Promise<IHttpResponse<IPostPromotions>> {
    return serverAxios.post(PromotionsHttp.saveRoute, data);
  }

  public editPromotion(data: FormData, id: number): Promise<IHttpResponse<IPostPromotions>> {
    return serverAxios.post(PromotionsHttp.updateRoute + id, data);
  }
  public getPromotion(params: { title?: string }): Promise<IHttpResponse<IPromotionsResponse>> {
    return serverAxios.get(PromotionsHttp.listRoute, { params });
  }

  public deletePromotion(id: number): Promise<IHttpResponse<null>> {
    return serverAxios.delete(PromotionsHttp.deleteRoute + id);
  }
  public activePromotions(): Promise<IHttpResponse<Array<IPromotionDetails>>> {
    return serverAxios.get(PromotionsHttp.activeRoute);
  }

  public activePromotionsAmerigo(id: string): Promise<IHttpResponse<Array<IPromotionDetails>>> {
    return serverAxios.get(PromotionsHttp.activeAmerigo + id);
  }
  public infoPromotion(id: number): Promise<IHttpResponse<IPromotionDetails>> {
    return serverAxios.get(PromotionsHttp.infoRoute + id);
  }
}

interface IPostPromotions {
  start_date: string;
  end_date: string
  image: string;
  pdf: string;
}


export interface IPromotionsResponse {
  page: number;
  per_page: number;
  total_count: number;
  total_pages: number;
  data: Array<IPromotionDetails>;
}

export interface IPromotionDetails {
  id: string;
  pdf_path: string;
  enabled: boolean;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  is_on_going: boolean;
  zone_commercial_code: string;
  images_path: {
    desktop: string
    mobile: string
  }
  translations: Array<{
    locale: string;
    title: string;
    description: string;
  }>
}

export interface IPromotionDetailsActive {
  id: number;
  title: string;
  discount: string;
  availability: string;
  description: string;
  image: string;
  pdf: string;
}


export default PromotionsHttp;
