import React, { useEffect, useState } from 'react';
import { IAddToCartParams } from 'models/car-parts.model';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { currencies } from 'utils/enums/marketplace';
import { addToCartAction } from 'store/actions/cart.actions';
import { setQuantityToolbar } from 'store/slices/cart.slice';
import { useAppDispatch } from 'utils/hooks/redux';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { CATALOG_REL } from 'utils/constants/defaultConstants';
import { Marketplaces } from 'utils/constants/applications';
import { getCurrentMarketplace, getMarketplaceBaseUrl, notify } from 'utils/marketplace';
import Spinner from 'components/spinner/Spinner';
import IconButton from 'components/buttons/IconButton/IconButton';
import Disponibility from 'components/disponibility/Disponibility';
import CarPartPrice from 'components/CarPartPrice/CarPartPrice';
import QuantityInput from 'components/inputs/QuantityInput/QuantityInput';
import './ItemViewModal.scss';
import { faFileClipboard } from '@fortawesome/free-regular-svg-icons';
import SwiperSlider from 'components/SwiperSlider/SwiperSlider';
import { SwiperSlide } from 'swiper/react';
import useHttp from 'utils/hooks/useHttp';
import { IBodyResponseTyre } from 'interfaces/tyre';
import { IBodyResponseOil } from 'interfaces/oil';
import { IBodyResponseConsumable } from 'interfaces/consumable';
import { IBodyResponseBattery } from 'interfaces/battery';
import ItemSliderProduct from './ItemSliderProduct/ItemSliderProduct';


type Props = {
  setOpenModal: (value: boolean) => void;
  data;
}
const c = 'item-view-modal';

const ItemViewModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { quantityToolbar } = useSelector((state: RootState) => state.cart);
  const { addToCartLoading } = useSelector((state: RootState) => state.cart);
  const dispatch = useAppDispatch();
  const marketplace = getCurrentMarketplace();
  const [searchParams] = useSearchParams();
  const [quantityValue, setQuantityValue] = useState<number>(Number(props.data?.available.uv) || 1);
  const [showQuantityAndCart, setShowQuantityAndCart] = useState<boolean>(false);
  const [disableCartBtn, setDisableCartBtn] = useState(false);
  const [loadingSlideCategory, setLoadingSlideCategory] = useState(false);
  const [loading, setLoading] = useState(true);
  const http = useHttp();
  const [refSlideData, setSlideData] = useState<IBodyResponseTyre | IBodyResponseOil | IBodyResponseConsumable | IBodyResponseBattery>();
  const [dataItem, setDataItem] = useState(props.data);

  function getPrice() {
    if (dataItem.price?.price) {
      return dataItem.price.price;
    }

    return dataItem.price;
  }

  function calcLimit() {
    if (typeof dataItem.available === 'object' && 'quantity' in dataItem.available) {
      return dataItem.available['quantity'];
    }

    if (dataItem.available && typeof dataItem.available === 'object') {
      const newLimit: { uv?: string; warehouse_main } = { ...dataItem.available };

      delete newLimit.uv;

      return Object.values(newLimit).reduce((acc, item) => Number(acc) + Number(item?.quantity), 0);
    }

    return dataItem.available;
  }

  function getAvailablityTitle() {
    if (dataItem?.available?.warehouse_main) {
      const warehouses = { ...dataItem.available };

      let totalQuantity = 0;
      let deliveryTime = 0;

      Object.keys(warehouses).forEach((key) => {
        if (key !== 'uv' && warehouses.hasOwnProperty!(key)) {
          totalQuantity += Number(warehouses[key].quantity);

          if (warehouses[key].warehouse_delivery_time) {
            deliveryTime += Number(warehouses[key].warehouse_delivery_time);
          }
        }
      });

      if (quantityValue <= Number(warehouses.warehouse_main?.quantity)) {
        return 'Disponible:';
      }

      if (quantityValue > Number(warehouses.warehouse_main?.quantity) && quantityValue <= totalQuantity) {
        return `Disponible dans un délai de ${deliveryTime} jours:`;
      }

      if (!getPrice()) {
        return '';
      }

      return '';
    }

    if (dataItem.available?.quantity) {
      if (dataItem.available.quantity >= quantityValue) {
        return 'Disponible: ';
      }
    }
  }

  const handleAddToCart = () => {
    if (addToCartLoading || !quantityValue || !dataItem.add_to_cart) {
      return;
    }

    const params: IAddToCartParams[] = [];
    let param: IAddToCartParams;

    if (dataItem?.catalog_type === 'tyre') {
      param = {
        tyre_id: dataItem.tyre_id,
        price: dataItem.price,
        quantity: quantityValue,
      };
    }
    else if (dataItem?.catalog_type === 'battery') {
      param = {
        battery_id: dataItem.battery_id,
        price: getPrice(),
        quantity: quantityValue,
      };
    }
    else if (dataItem?.catalog_type === 'oil') {
      param = {
        oil_id: dataItem.oil_id,
        price: getPrice(),
        quantity: quantityValue,
      };
    }
    else if (dataItem?.catalog_type === 'consumable') {
      param = {
        consumable_id: dataItem.consumable_id,
        price: getPrice(),
        quantity: quantityValue,
      };
    }
    else {
      param = {
        catalog_id: searchParams.get('catalog_id') ? String(searchParams.get('catalog_id')) : CATALOG_REL,
        cod_fou: dataItem?.cod_fou,
        is_oe_part: false,
        price: dataItem?.price?.price ? dataItem?.price?.price : dataItem?.price,
        quantity: quantityValue,
        supplier_id: dataItem?.data_supplier.id,
        supplier_reference: dataItem?.data_supplier_reference,
      };
    }

    if (localStorage.getItem('external_cart_id')) {
      param = {
        ...param,
        ...{ external_cart_id: localStorage.getItem('external_cart_id') },
      };
    }

    if (dataItem.catalog_id === 'CNT') {
      param = {
        non_tecdoc_reference: dataItem.data_supplier_reference_original,
        supplier_id: dataItem?.data_supplier.id,
        price: dataItem?.promotion_price
          ? dataItem.promotion_price
          : dataItem.price?.price
            ? dataItem.price?.price
            : null,
        quantity: quantityValue,
        is_oe_part: false,
      };
    }

    params.push(param);

    dispatch(addToCartAction(params))
      .then(() => {
        dispatch(setQuantityToolbar(+quantityValue + +quantityToolbar));

        props.setOpenModal(false);
      });
  };

  function displayCartBlock() {
    setShowQuantityAndCart(![Marketplaces.PUBLIC, Marketplaces.PUBLIC_TR, Marketplaces.AMERIGO].includes(marketplace)
      || (marketplace == Marketplaces.AMERIGO && searchParams.get('is_platform_admin') !== 'true'));
  }

  function disableCart() {
    if (disableCartBtn) {
      return true;
    }

    if (Number(calcLimit()) < quantityValue && !dataItem?.add_to_cart) {
      return true;
    }

    if (addToCartLoading || !quantityValue) {
      return true;
    }

    // if (Number(calcLimit()) === 0 && !getPrice()) {
    //   return true;
    // }

    if (Number(calcLimit()) < quantityValue && dataItem?.add_to_cart) {
      return false;
    }
  }

  function renderLabel(label) {
    if (dataItem?.catalog_type === 'battery') {
      return t(`_battery_${label}`);
    }

    if (dataItem?.catalog_type === 'oil') {
      return t(`_oil_${label}`);
    }

    return t(`_${label}`);
  }

  useEffect(() => {
    setLoading(true);
    displayCartBlock();

    if (!calcLimit()) {
      setDisableCartBtn(true);
    } else {
      setDisableCartBtn(false);

    }

    const timer = setTimeout(() => {

      setLoading(false);
    }, 500);

    return () => clearTimeout(timer); // Clean up timeout if the component is unmounted or dataItem changes
  }, [dataItem]);

  useEffect(() => {


    switch (dataItem.catalog_type) {
      case 'battery':
        getBatteriesData();

        break;
      case 'tyre':
        getTyresData();

        break;
      case 'oil':
        getOilData();

        break;
      case 'consumable':
        getConsumableData();

        break;
    }

  }, []);


  function download() {
    const link = document.createElement('a');

    link.href = dataItem?.file || '#';
    link.setAttribute('download', dataItem?.file?.substring(dataItem?.file?.lastIndexOf('/') + 1, dataItem?.file?.lastIndexOf('.')) || '');

    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(dataItem.data_supplier_reference_original);
    notify(t('_reference_copied'), 'success');
  }

  function getTyresData() {
    setLoadingSlideCategory(true);

    const params = {
      season_ids: [dataItem.category.id],
      per_page: 1000
    };

    http.tyreHttp
      .getTyresProducts(params)
      .then((res) => {
        setSlideData(res.data);
        setLoadingSlideCategory(false);
      })
      .catch(() => {

        setLoadingSlideCategory(false);

      });
  }

  function getBatteriesData() {

    setLoadingSlideCategory(true);
    const params: {
      category_ids: string[];
      per_page: number;
    } = {
      category_ids: [dataItem.category.id],
      per_page: 1000
    };

    http.batteriesHttp
      .getBatteries(params)
      .then((res) => {
        setSlideData(res.data);
        setLoadingSlideCategory(false);
      })
      .catch(() => {
        setLoadingSlideCategory(false);
      });

  }

  function getOilData() {
    setLoadingSlideCategory(true);

    const params: {
      category_ids: string[];
      per_page: number;
    } = {
      category_ids: [dataItem.category.id],
      per_page: 1000
    };

    http.oilCatalogHttp
      .getOils(params)
      .then((res) => {
        setSlideData(res.data);
        setLoadingSlideCategory(false);
      })
      .catch(() => {
        setLoadingSlideCategory(false);
      });
  }

  function getConsumableData() {
    setLoadingSlideCategory(true);

    const params: {
      category_ids: string[];
      per_page: number;
    } = {
      category_ids: [dataItem.category.id],
      per_page: 1000
    };

    http.consumableCatalogHttp
      .getConsumables(params)
      .then((res) => {
        setSlideData(res.data);
        setLoadingSlideCategory(false);
      })
      .catch(() => {
        setLoadingSlideCategory(false);
      });
  }

  return (
    <div className={c}>
      <div className={`${c}__header`}>
        <div className={`${c}__header-close`}>
          <FontAwesomeIcon icon={faTimes} onClick={() => props.setOpenModal(false)} />
        </div>
      </div>
      <div className={`${c}__content`}>
        {
          loading ? <Spinner class="small" /> :
            <React.Fragment>


              <div className={`${c}__content-left`}>
                <div className={`${c}__content-left-image`}>
                  <img
                    src={dataItem.image || dataItem.data_supplier_logo || '/images/car-part-placeholder.png'}
                    className={`${c}__content-left-image-product`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = '/images/car-part-placeholder-big.png';
                    }}
                  />
                  {dataItem.data_supplier_logo && (
                    <img src={dataItem.data_supplier_logo} className={`${c}__content-left-image-supplier`} />
                  )}
                </div>
                {dataItem?.barcode?.code ? (
                  <div className="barcode">
                    <img src={`${getMarketplaceBaseUrl()}/${dataItem.barcode.image}`} alt="barcode" />
                    <div className="barcode-number">{dataItem.barcode.code}</div>
                  </div>
                ) : null}
                {dataItem?.file && (
                  <div className="button">
                    <IconButton
                      onClick={download}
                      text={t('_technical_sheet')}
                      className="item-technical_sheet"
                      icon={
                        <FontAwesomeIcon icon={faDownload} className="" />
                      }
                    />
                  </div>
                )}

              </div>
              <div className={`${c}__content-right`}>
                <div className={`${c}__content-right-tags`}>
                  <span className={`${c}__content-right-tags-tag`}>
                    <span className="label"> <span className="placeholderRed"> * </span> {t('_reference')}:</span>
                    <span className="value">{String(dataItem?.data_supplier_reference_original)} <FontAwesomeIcon icon={faFileClipboard} onClick={copyToClipboard} /></span>
                  </span>
                  {dataItem.short_description && (
                    <span className={`${c}__content-right-tags-tag`}>
                      <span className="label"> <span className="placeholderRed"> * </span> {t('_short_description')}:</span>
                      <span className="value">{String(dataItem?.short_description)} </span>
                    </span>
                  )}
                  <span className={`${c}__content-right-tags-tag`}>
                    <span className="label"> <span className="placeholderRed"> * </span> {t('_mark')}:</span>
                    <span className="value">{String(dataItem?.data_supplier?.name)}</span>
                  </span>
                  {Array.isArray(dataItem.tags) ?
                    dataItem.tags?.map((tag) => tag.value && `${renderLabel(tag.name)}: ${tag.value}; `) :
                    Object.keys(dataItem.tags)?.map((characteristic: string, key: number) => {
                      if (dataItem.tags[characteristic] && dataItem.tags[characteristic].length > 0 && dataItem.tags[characteristic] !== 'null') {
                        return (
                          <span className={`${c}__content-right-tags-tag`} key={key}>
                            <span className="placeholderRed"> * </span> <span className="label">{renderLabel(characteristic)}:</span>
                            <span className="value">{t(String(dataItem.tags[characteristic]))}</span>
                          </span>
                        );
                      }
                    }
                    )}
                  {dataItem.description && (
                    <div className={`${c}__content-right-description`}>
                      <div className={`${c}__content-right-description-label`}>
                        {t('_description')}:
                      </div>
                      <div className={`${c}__content-right-description-value`}>
                        {dataItem.description}
                      </div>
                    </div>
                  )}
                </div>
                <div className={`${c}__content-right-actions`}>
                  {showQuantityAndCart && (
                    <div className="bottom">

                      <div className="availability">
                        <QuantityInput setValue={setQuantityValue} value={quantityValue} uv={dataItem?.available?.uv} />
                        <Disponibility
                          value={quantityValue}
                          limit={dataItem?.available}
                          title={getAvailablityTitle()}
                          price={dataItem.price}
                        />
                        <CarPartPrice
                          priceVAT={Number(dataItem?.price?.price_with_vat)}
                          quantityValue={quantityValue}
                          currency={currencies.EUR}
                          price={Number(dataItem?.price?.price)}
                        />
                      </div>
                      {dataItem?.add_to_cart && (
                        <div className="button">
                          <IconButton
                            disabled={disableCart()}
                            onClick={handleAddToCart}
                            text={t('_add_to_cart')}
                            className="item-cart_btn"
                            icon={
                              addToCartLoading ? <Spinner class="extra-small"></Spinner> : <FontAwesomeIcon icon={faCartShopping} className="" />
                            }
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
        }
      </div>


      <div className={`brand-slider-modal ${refSlideData && refSlideData.data.length ? 'brand-slider-modal-show' : ''}`}>

        {refSlideData && refSlideData.data.length > 0 ?
          <SwiperSlider
            slidesPerView="auto"
            navigation
            loop
            //autoplay
            isLoading={loadingSlideCategory}
            delay={5000}
          >
            {
              refSlideData.data.map((item, index: number) => {

                return (
                  <SwiperSlide className="swiper-slide-modal" key={index + index}>
                    <ItemSliderProduct item={item} key={index} setDataItem={setDataItem} />
                  </SwiperSlide>
                );


              })
            }
          </SwiperSlider> : null
        }
      </div>

    </div >
  );
};

export default ItemViewModal;