import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import './SortBy.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpAZ, faArrowUpZA } from '@fortawesome/free-solid-svg-icons';
import Select, { SingleValue } from 'react-select';
import { ISortByOption } from '../../interfaces/select';
import { sortByOptions } from './SortBy.map';
import {getTranslationName} from 'utils/locale';

type Props = {
  fetchData?
}

const SortBy: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const sortByOptionsTranslated: ISortByOption[] = sortByOptions.map((item) => {return { ...item, label: getTranslationName(item.label) };});

  const [selectedOption, setSelectedOption] = useState<SingleValue<ISortByOption>>(
    sortByOptionsTranslated.filter((item) => String(item.value) == String(searchParams.get('sort')))[0] ?? [],
  );

  const [sortOrder, setSortOrder] = useState<boolean>(
    Boolean((searchParams.get('asc') && searchParams.get('asc') === 'true') ?? selectedOption?.default_sort_asc ?? true),
  );

  const handleSortByColumn = (option: SingleValue<ISortByOption>) => {
    const selOption = sortByOptionsTranslated.filter((item) => String(item.value) == String(option?.value))[0] ?? [];

    setSelectedOption(selOption);
    setSortOrder(selOption?.default_sort_asc);

    if (!option?.value) {
      searchParams.delete('sort');
      searchParams.delete('asc');
    } else {
      searchParams.set('sort', String(option?.value));
      searchParams.set('asc', String(selOption?.default_sort_asc));
    }

    setSearchParams(searchParams);
  };

  const handleSortByOrder = (sortAsc: boolean) => {
    setSortOrder(sortAsc);

    if (!selectedOption?.value) {
      return;
    }

    searchParams.set('sort', String(selectedOption?.value));
    searchParams.set('asc', String(sortAsc));
    setSearchParams(searchParams);
  };

  return (
    <div className="sort-by-container">
      <span>{t('_sort_by')}</span>
      <Select
        className="sort-select-container"
        placeholder={t('_choose_value')}
        defaultValue={sortByOptionsTranslated[0]}
        value={selectedOption}
        onChange={(val) => {
          handleSortByColumn(val);

          if(props.fetchData){
            props?.fetchData(val?.value);
          }
        }}
        options={sortByOptionsTranslated}
      />
      <div
        className="sort-order-icon"
        onClick={() => {
          handleSortByOrder(!sortOrder);

          if(props.fetchData){
            props?.fetchData();
          }
        }}
      >
        {selectedOption?.value && <FontAwesomeIcon className="fa-light" icon={sortOrder ? faArrowUpAZ : faArrowUpZA} />}
      </div>
    </div>
  );
};

export interface SortByParams {
  sort_by_column: string;
  sort_by_asc?: string;
  search?: string;
}

export default SortBy;
