import Footer from 'components/footer/Footer';
import { SidebarContextProvider } from 'context/SidebarContext';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import EbooksSidebar from 'applications/automotor/components/espace/navbar/EbooksSidebar/EbooksSidebar';
import DefaultToastContainer from 'components/toast-container/DefaultToastContainer';
import RetailerSidebar from 'applications/retailer/views/RetailerSidebar/RetailerSidebar';
import 'applications/DefaultCssV2.scss';
import NavbarMobile from 'components/navbar/Mobile/NavbarMobile';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import NavbarMecadepot from 'applications/retailer/components/navbar/NavbarMecadepot';
import { IPromotionDetails } from 'utils/hooks/http/promotions.http';
import useHttp from 'utils/hooks/useHttp';
import InterstitialAd from 'components/AdsModule/InterstitialAd/InterstitialAd';
import Spinner from 'components/spinner/Spinner';
import Modal from 'components/modal/Modal';

const MainLayoutMecadepot: React.FC = () => {
  const [showEbooks, setShowEbooks] = useState(false);
  const { isMobile, isTablet } = useDeviceDetect();

  const http = useHttp();

  const [seenAd, setSeenAd] = useState<string | null>(localStorage.getItem('ads-int-seen'));
  const [loadingAd, setLoadingAd] = useState<boolean>(false);
  const [adItems, setAdItems] = useState<IPromotionDetails[]>([]);

  const handleCloseEbook = () => {
    setShowEbooks(() => false);
  };

  const handleCloseAd = () => {
    localStorage.setItem('ads-int-seen', 'yes');
    setSeenAd('yes');
    setAdItems([]);
  };

  function getAds() {
    setLoadingAd(true);
    http.promotions.activePromotions().then((res) => {
      if (res.data.length) {
        setAdItems(res.data);
      } else {
        setSeenAd('no-data');
      }

    }).finally(() => {
      setLoadingAd(false);

    }).catch(() => {
      setSeenAd(null);
      setLoadingAd(false);
      setAdItems([]);
    });
  }

  useEffect(() => {
    if (!seenAd) {
      getAds();
    }
  }, [localStorage.getItem('ads-int-seen')]);

  return (
    <div className={`container-wrapper-v2 ${!seenAd ? 'ads-module-intersitial-ad' : ''} ${isMobile ? 'app-layout-mobile' : isTablet ? 'app-layout-tablet' : 'app-layout-desktop'}`}>

      {!seenAd && (
        loadingAd ? <Spinner class="small" /> :
          <Modal
            openModal
            childrenComponent={
              <InterstitialAd
                items={adItems}
                slidesPerView={1}
                navigation={!(isMobile || isTablet) && adItems.length != 1}
                pagination={isMobile || isTablet}
                autoplay={adItems.length != 1}
                loop={adItems.length != 1}
                onClick={handleCloseAd}
              />}
            contentStyle={
              {
                content: {
                  maxWidth: '95%'
                },
                overlay: {
                  zIndex: 20,
                }
              }
            }
          />)

      }
      <DefaultToastContainer />
      <SidebarContextProvider>
        <div className="container">
          <div className="header">
            {isMobile || isTablet ?
              <React.Fragment>
                <NavbarMobile />
                <EbooksSidebar showEbooks={showEbooks} handleCloseEbook={handleCloseEbook} />
              </React.Fragment>
              :
              <React.Fragment>
                <NavbarMecadepot />
                <EbooksSidebar showEbooks={showEbooks} handleCloseEbook={handleCloseEbook} />
              </React.Fragment>
            }
          </div>
          <div className="sidebar">
            <RetailerSidebar />
          </div>
          <div className="body">

            <Outlet />
          </div>
          <div className="footer">
            <Footer />
          </div>
        </div>
      </SidebarContextProvider>
    </div>
  );
};

MainLayoutMecadepot.displayName = 'MainLayoutMecadepot';

export default MainLayoutMecadepot;
