import React, { useRef } from 'react';

import { AiOutlineAppstore } from 'react-icons/ai';
import { GiCarWheel } from 'react-icons/gi';
import { FaCarBattery, FaGripVertical, FaOilCan, FaSprayCan } from 'react-icons/fa';
import './Sidebar.scss';
import List from '../../components/List/List';
import { ICategoryTab, TabCategories, ITyreSeason, IOilCategory, ITyreCategory, ITyreBrand, IConsumableBrands, IConsumableCategories, IBatteryBrands, IPartSupplier } from './Sidebar.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { IItemClick } from 'models/catalog.model';
import Spinner from 'components/spinner/Spinner';
import { getMarketplaceBaseUrl } from 'utils/marketplace';
import { useOnClickOutside } from 'utils/hooks/useOnClickOutside';
import { IPartsCategory } from 'models/vehicle-catalogs';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { IBodyResponseBatteryCategory } from 'interfaces/battery';
import { useNavigate } from 'react-router-dom';
import { usePermission } from 'utils/usePermission';
import { ROLE_CLIENT_PLATFORM_ADMIN } from 'utils/constants/roles';
import { getTranslationName } from '../../utils/locale';

type Props = {
  sideBarTab: TabCategories | null;
  setSidebarTab: (tab: TabCategories | null) => void;
  closeSidebar: () => void;
  loading: boolean;
  oilCategories: IOilCategory[];
  consumableBrands: IConsumableBrands[];
  consumableCategories: IConsumableCategories[];
  tyresCategories: ITyreCategory | undefined;
  categories: IPartsCategory[];
  onItemClick?: (item: IItemClick) => void;
  isEnabled?: boolean;
  batteryBrands: IBatteryBrands[];
  partsSuppliers: IPartSupplier[]
  batteryCategory: IBodyResponseBatteryCategory[];
};


const SidebarView: React.FC<Props> = (props) => {

  const { user } = useSelector((state: RootState) => state.userData);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isPlatformAdmin = usePermission([ROLE_CLIENT_PLATFORM_ADMIN]);
  const vehicleSelected = localStorage.getItem('carSelected') ? JSON.parse(localStorage.getItem('carSelected') as string) : null;

  const tabs: ICategoryTab[] = [
    { id: TabCategories.Pieces, status: vehicleSelected !== null && !location.pathname.includes('/espace-clients'), header: t('_pieces'), icon: <AiOutlineAppstore className="icon" />, disabled: false },
    { id: TabCategories.Pneus, status: user?.hasTyre && isPlatformAdmin, header: t('_tyre'), icon: <GiCarWheel className="icon" />, disabled: !props.isEnabled },
    { id: TabCategories.Huiles, status: user?.hasOil && isPlatformAdmin, header: t('_oil'), icon: <FaOilCan className="icon" />, disabled: !props.isEnabled },
    { id: TabCategories.Consumables, status: user?.hasConsumable && isPlatformAdmin, header: t('_consumables'), icon: <FaSprayCan className="icon" />, disabled: !props.isEnabled },
    { id: TabCategories.Battery, status: user?.hasBattery && isPlatformAdmin, header: t('_battery'), icon: <FaCarBattery className="icon" />, disabled: !props.isEnabled },
    { id: TabCategories.Parts, status: user?.has_nonTecDocCatalog && isPlatformAdmin, header: t('_parts'), icon: <FaGripVertical className="icon" />, disabled: !props.isEnabled },
  ];

  const activeTabs = tabs.filter((tab) => tab.status);

  const marketplace = getMarketplaceBaseUrl();
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useOnClickOutside(sidebarRef, props.closeSidebar);

  return (
    <div ref={sidebarRef} className="container-sidebar">
      <FontAwesomeIcon icon={faXmark} onClick={props.closeSidebar} className="close-btn" />
      <div className="tabs">
        {activeTabs.map((tab: ICategoryTab) => {

          return (
            <div
              key={tab.id}
              onClick={() => props.setSidebarTab(tab.id)}
              className={`${'tab'} ${tab.id == props.sideBarTab ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`}
            >
              {tab.icon}
              <div className="tab-name">{tab.header}</div>
            </div>
          );
        })}
      </div>
      <div className="content-list">
        {props.sideBarTab === 'pieces' && (
          <div className="content">
            <List title={t('_spare_parts_catalog')}
              content={props.categories.map((item: IPartsCategory, index) => {
                return { id: index, name: item.name, type: 'pieces', params: 'pieces', item };
              })}
              images={props.categories.map((item: IPartsCategory) => item.image)}
              onItemClick={props.onItemClick}
              itemClassName="listItem"
            ></List>
          </div>
        )}
        {props.loading ? (
          <div className="content">
            <Spinner class="small" />
          </div>
        ) : (
          <div>
            {props.sideBarTab === 'pneus' && (
              <div className="content">
                <h1>{t('_tire_catalog')}</h1>
                <div className="search">
                  {/* <input type="text" className={'input']} placeholder="Dimension du pneu" />
                  <AiOutlineSearch className={'icon']} />
                  <AiFillQuestionCircle className={'question']} /> */}

                  <button className="btn-catalog" type="button" onClick={() => { navigate('/espace-clients/my-eshop/catalog_tyres'), props.closeSidebar(); }}>
                    <GiCarWheel />  {t('_view_catalog')}
                  </button>
                </div>
                <List
                  title={t('_season')}
                  content={props.tyresCategories?.seasons.map((item: ITyreSeason) => {
                    return { id: item.id, name: getTranslationName(item.name), type: 'tyre', params: 'season_ids[]' };
                  })}
                  className="title"
                  itemClassName="listItem"
                  onItemClick={props.onItemClick}
                ></List>
                {props.tyresCategories?.qualities && (
                  <div>
                    <h1>{t('_brand_tire_catalog')}</h1>

                    {props.tyresCategories.qualities?.PREMIUM && (
                      <List
                        title={t('_premium')}
                        content={props.tyresCategories.qualities?.PREMIUM.map((item: ITyreBrand) => {
                          return { id: item.brand_id, name: item.brand_name, type: 'tyre', params: 'brand_ids[]' };
                        })}
                        images={props.tyresCategories.qualities?.PREMIUM.map((item: ITyreBrand) => item.brand_image)}
                        className="subtitle"
                        itemClassName="listItem"
                        onItemClick={props.onItemClick}
                      />
                    )}
                    {props.tyresCategories.qualities?.QUALITY && (
                      <List
                        title={t('_quality')}
                        content={props.tyresCategories.qualities?.QUALITY.map((item: ITyreBrand) => {
                          return { id: item.brand_id, name: item.brand_name, type: 'tyre', params: 'brand_ids[]' };
                        })}
                        images={props.tyresCategories.qualities?.QUALITY.map((item: ITyreBrand) => item.brand_image)}
                        className="subtitle"
                        itemClassName="listItem"
                        onItemClick={props.onItemClick}
                      />
                    )}
                    {props.tyresCategories.qualities?.BUDGET && (
                      <List
                        title={t('_budget')}
                        content={props.tyresCategories.qualities?.BUDGET.map((item: ITyreBrand) => {
                          return { id: item.brand_id, name: item.brand_name, type: 'tyre', params: 'brand_ids[]' };
                        })}
                        images={props.tyresCategories.qualities?.BUDGET.map((item: ITyreBrand) => item.brand_image)}
                        className="subtitle"
                        itemClassName="listItem"
                        onItemClick={props.onItemClick}
                      />
                    )}
                  </div>
                )}
              </div>
            )}

            {props.sideBarTab === 'huiles' && (
              <div className="content">
                <h1>{t('_oils_catalog')}</h1>
                <div className="search">

                  <button className="btn-catalog" type="button" onClick={() => { navigate('/espace-clients/my-eshop/catalog_oil'), props.closeSidebar(); }}>
                    <FaOilCan />   {t('_view_catalog')}
                  </button>
                </div>
                {
                  props.oilCategories.length ?
                    <List
                      content={props.oilCategories.map((item: IOilCategory) => {
                        return { id: item.category_id, name: item.category_name, type: 'oil' };
                      })}
                      images={props.oilCategories.map((item: IOilCategory) => item.category_image)}
                      itemClassName="listItem"
                      onItemClick={props.onItemClick}
                    /> : null
                }
              </div>
            )}

            {props.sideBarTab === 'consumables' && (
              <div className="content">
                <h1>{t('_consumable_catalog')}</h1>
                <div className="search">

                  <button className="btn-catalog" type="button" onClick={() => { navigate('/espace-clients/my-eshop/catalog_consumable'), props.closeSidebar(); }}>
                    <FaSprayCan />    {t('_view_catalog')}
                  </button>
                </div>
                {
                  props.consumableBrands.length ?
                    <List
                      title={t('_brand')}
                      content={props.consumableBrands.map((item: IConsumableBrands) => {
                        return { id: item.id, name: item.name, type: 'consumables', params: 'brand_ids[]' };
                      })}
                      images={props.consumableBrands.map((item: IConsumableBrands) => marketplace + item.image)}
                      itemClassName="listItem"
                      onItemClick={props.onItemClick}
                    />
                    : null
                }
                {
                  props.consumableCategories.length ?
                    <List
                      title={t('_category')}
                      content={props.consumableCategories.map((item: IConsumableCategories) => {
                        return { id: item.id, name: item.name, type: 'consumables', params: 'category_ids[]' };
                      })}
                      images={props.consumableCategories.map((item: IConsumableCategories) => marketplace + item.image)}
                      itemClassName="listItem"
                      onItemClick={props.onItemClick}
                    />
                    : null
                }
              </div>
            )}

            {props.sideBarTab === 'battery' && (
              <div className="content">
                <h1>{t('_catalog_battery')}</h1>
                <div className="search">

                  <button className="btn-catalog" type="button" onClick={() => { navigate('/espace-clients/my-eshop/catalog_battery'), props.closeSidebar(); }}>
                    <FaCarBattery />    {t('_view_catalog')}
                  </button>
                </div>
                {
                  props.batteryBrands.length ?
                    <List
                      title={t('_brand')}
                      content={props.batteryBrands.map((item: IBatteryBrands) => {
                        return { id: item.id, name: item.name, type: 'battery', params: 'brand_ids[]' };
                      })}
                      images={props.batteryBrands.map((item: IBatteryBrands) => marketplace + item.image)}
                      itemClassName="listItem"
                      onItemClick={props.onItemClick}
                    /> : null
                }
                {
                  props.batteryCategory.length ?
                    <List
                      title={t('_category')}
                      content={props.batteryCategory.map((item: IBodyResponseBatteryCategory) => {
                        return { id: item.id, name: item.name, type: 'battery', params: 'category_ids[]' };
                      })}
                      images={props.batteryCategory.map((item: IBodyResponseBatteryCategory) => marketplace + item.image)}
                      itemClassName="listItem"
                      onItemClick={props.onItemClick}
                    /> : null
                }
              </div>

            )}
            {props.sideBarTab === 'parts' && (
              <div className="content">
                <h1>{t('_non_tecdoc_parts')}</h1>
                <div className="search">

                  <button className="btn-catalog" type="button" onClick={() => { navigate('/espace-clients/my-eshop/catalog_non_tecdoc'), props.closeSidebar(); }}>
                    {t('_view_catalog')}
                  </button>
                </div>
                {
                  props.partsSuppliers.length ?
                    <List
                      title={t('_suppliers')}
                      content={props.partsSuppliers.map((item: IPartSupplier) => {
                        return { id: item.id, name: item.name, type: 'parts', params: 'supplier_ids[]' };
                      })}
                      images={props.partsSuppliers.map((item: IPartSupplier) => marketplace + item.image)}
                      itemClassName="listItem"
                      onItemClick={props.onItemClick}
                    /> : null
                }
              </div>

            )}

          </div>
        )}
      </div>
    </div >
  );
};

SidebarView.displayName = 'SidebarView';

export default SidebarView;
