import React from 'react';
import PublicFooter from 'applications/public/components/PublicFooter/PublicFooter';
import { SidebarContextProvider } from 'context/SidebarContext';
import { Outlet } from 'react-router-dom';
import Sidebar from 'views/Sidebar/Sidebar';
import '../../../DefaultCss.scss';
import PublicNavbar from 'applications/public/components/PublicNavbar/PublicNavbar';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';

type Props = {}

const PublicLayout: React.FC<Props> = () => {
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <div className={` container-wrapper ${isMobile ? 'app-layout-mobile' : isTablet ? 'app-layout-tablet' : 'app-layout-desktop'}`}>
      <div className="container">
        <SidebarContextProvider>
          <div className="sidebar">
            <Sidebar />
          </div>
          <div className="header">
            <PublicNavbar />
          </div>
          <div className="body">
            <Outlet />
          </div>
          <div className="footer">
            <PublicFooter />
          </div>
        </SidebarContextProvider>
      </div>
    </div>
  );
};

export default PublicLayout;