import React, { useEffect, useRef } from 'react';
import { faClose, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DefaultButton from 'components/buttons/DefaultButton/DefaultButton';
import LinkButton from 'components/buttons/LinkButton/LinkButton';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import useReliableCatalogCarQueryParams from 'utils/hooks/useReliableCatalogCarQueryParams';
import { useDeviceDetect } from 'utils/hooks/deviceDetect';
import './CatalogConsumableFilter.scss';

type Props = {
  setOpenFilter?: (boolean) => void;
  openFilter?: boolean;
  filterData?;
  getData?;
};

const CatalogConsumableFilter: React.FC<Props> = (props) => {
  const { control, getValues, reset, setValue } = useForm();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const filterRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const reliableSearchParams = useReliableCatalogCarQueryParams();
  const { isMobile, isTablet } = useDeviceDetect();

  // useOnClickOutside(filterRef, () => {
  //   props.setOpenFilter!(false);
  // });


  const getOptions = (key) => {

    if (props.filterData && (key in props.filterData)) {
      return (
        props.filterData[key]?.map((item) => ({
          label: item.key,
          value: item.value,
        })) || []
      );
    }
  };


  function handleClick() {
    const params = {
      brand_id: Array.isArray(getValues()?.brand) ? getValues()?.brand?.map(({ value }) => value) : (getValues()?.brand?.value && [getValues()?.brand?.value]),
      category_id: searchParams.get('category_id'),
      page: searchParams.get('page'),
      q: searchParams.get('q')
    };

    const validParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (!value || !value.length) {
        return acc;
      }

      acc[key] = value;

      return acc;
    }, {} as { [key: string]: string | string[] });

    setSearchParams({ ...validParams, ...reliableSearchParams });

    props.setOpenFilter!(false);
  }

  function handleReset() {
    reset({ brand: '' });
    setSearchParams({ ...reliableSearchParams, ...{ category_id: String(searchParams.get('category_id')) } });
    props.setOpenFilter!(false);
  }

  useEffect(() => {
    const foundBrands = props.filterData && props.filterData?.brand?.filter(item => {
      return searchParams.getAll('brand_id').includes(String(item.value));
    });

    if (foundBrands?.length) {
      setValue('brand', foundBrands.map(item => {
        return { label: item.key, value: item.value };
      }));
    }
  }, [props.filterData]);

  return (
    <div ref={filterRef} className={`consumable-filters-catalog ${props.openFilter ? 'active' : ''}`}>
      <div className="header">
        <div className="buttons-container">
          <LinkButton title={t('_reset')} icon={faRedo} onClick={handleReset} />
        </div>
        <div onClick={() => props.setOpenFilter!(false)}>
          <FontAwesomeIcon className="icon" icon={faClose} />
        </div>
      </div>
      <div className="title">
        <Trans i18nKey="_filter_results" components={{ b: <strong /> }} />
      </div>
      <div className="filters">
        <div className="dropdowns-container">
          {/* <div className="dropdown-item">
            <label className="dropdown-label">{t('_consumable_category')}</label>
            <Controller
              control={control}
              name="category"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  isDisabled={value?.is_amerigo}
                  options={getOptions('category')}
                  placeholder={t('_consumable_category')}
                  isClearable={false}
                  menuPlacement="auto"
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div> */}
          <div className="dropdown-item">
            <label className="dropdown-label">{t('_consumable_brand')}</label>
            <Controller
              control={control}
              name="brand"
              render={({ field: { onChange, value } }) => (
                <Select
                  value={value}
                  isMulti
                  className="parts-filter-select-dropdown"
                  onChange={(val) => {
                    onChange(val);
                  }}
                  options={getOptions('brand')}
                  placeholder={t('_consumable_brand')}
                  menuPlacement="top"
                  isClearable={false}
                  menuPosition="fixed"
                  styles={{ menuPortal: base => ({ ...base, zIndex: 50 }) }}
                  isSearchable={!isMobile && !isTablet}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="apply-btn">
        <DefaultButton text={t('_apply')} onClick={() => handleClick()} />
      </div>
    </div>
  );
};

export default CatalogConsumableFilter;
