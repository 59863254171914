
import React from 'react';
import './ItemSliderProduct.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';


type Props = {
  item
  setDataItem
};

const ItemSliderProduct: React.FC<Props> = (props) => {

  return (<div className="item-container-offert">

    <div className="item-shop">
      <div className="item-column-1" >

        <div className="img">
          <img
            src={props.item.image || props.item.data_supplier_logo || '/images/car-part-placeholder.png'}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = '/images/car-part-placeholder-big.png';
            }}
          />
        </div>

      </div>
      <div className="item-column-2" >


        <div className="reference"> {props.item.data_supplier_reference}</div>
        <div className="reference"> {props.item?.data_supplier?.name} </div>

        <div className="description">
          <span> {props.item.description ? props.item.description : (props.item.short_description ? props.item.short_description : '')} </span>
        </div>

      </div>
      <div className="item-column-3" >
        <div className="show-item">
          <FontAwesomeIcon
            onClick={() => { props.setDataItem(props.item); }}
            icon={faSquareArrowUpRight}
          />
        </div>
      </div >
    </div >

  </div >);
};

export default ItemSliderProduct;
