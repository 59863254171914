import React, { useState } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faImage } from '@fortawesome/free-solid-svg-icons';
import IconButton from 'components/buttons/IconButton/IconButton';
import './ModalWebCam.scss';
import { useTranslation } from 'react-i18next';
import TextRecognition from 'components/tesseract/TextRecognition';
import CustomWebcam from 'components/webcam/CustomWebcam';

type Props = {
  setRecognizedText: (text: string) => void;
  setOpenModal: (e: boolean) => void;
};


const ModalWebCam: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const [selectedType, setSelectedType] = useState<string | null>(null);

  const handleIconClick = (type: string) => {
    setSelectedType(type);
  };

  return (
    <div className="modal-web-cam">
      {selectedType == null ?
        <div className="modal-web-select" >

          <div className="title" >
            {t('_select_type_image')}
          </div>
          <div className="icons-cam">
            <IconButton
              className="iconButton"
              onClick={() => handleIconClick('image')}
              icon={<FontAwesomeIcon icon={faImage} className="faImage" />}
            />

            <IconButton
              className="iconButton"
              onClick={() => handleIconClick('webCam')}
              icon={<FontAwesomeIcon icon={faCamera} className="faImage" />}
            />
          </div>

        </div>
        : null
      }

      <div className="modal-web-content">
        {selectedType === 'image' && <TextRecognition setRecognizedText={props.setRecognizedText} open={selectedType == null ? false : true} setOpenModal={props.setOpenModal} setSelectedType={setSelectedType} />}
        {selectedType === 'webCam' && <CustomWebcam setRecognizedText={props.setRecognizedText} setOpenModal={props.setOpenModal} setSelectedType={setSelectedType} />}
      </div>

    </div>
  );
};

export default ModalWebCam;
