import { addRouteParam, IHttpResponse, serverAxios } from 'utils/http';
import { IEinaStatusResponse } from './interface/eina.interface';

class EinaInternalHttp {

  private static baseUrl = '/retailer/eina';
  private static create = EinaInternalHttp.baseUrl + '/users/:id/create';
  private static activate = EinaInternalHttp.baseUrl + '/users/:id/activate';
  private static deactivate = EinaInternalHttp.baseUrl + '/users/:id/deactivate';

  public async create(id: number): Promise<IHttpResponse<IEinaStatusResponse>> {
    return serverAxios.post(addRouteParam(EinaInternalHttp.create, { id }));
  }

  public async activate(id: number): Promise<IHttpResponse<IEinaStatusResponse>> {
    return serverAxios.put(addRouteParam(EinaInternalHttp.activate, { id }));
  }

  public async deactivate(id: number): Promise<IHttpResponse<IEinaStatusResponse>> {
    return serverAxios.put(addRouteParam(EinaInternalHttp.deactivate, { id }));
  }
}

export default EinaInternalHttp;