import { addRouteParam, IHttpResponse, serverAxios } from '../../http';
import { IBodyResponseConsumable, IConsumable, IConsumableCatalogFilter } from 'interfaces/consumable';
import { CancelToken } from 'axios';

class ConsumablesCatalogHttp {
  private static ROUTE = 'consumable/products';
  private static VIEW_ROUTE = 'consumable/products/:id';
  private static FILTER_ROUTE = 'consumable/filters';

  public async getConsumables(
    urlParams: IConsumableCatalogParams,
    cancelToken?: CancelToken,
  ): Promise<IHttpResponse<IBodyResponseConsumable>> {
    return serverAxios.get(ConsumablesCatalogHttp.ROUTE, { params: urlParams, cancelToken });
  }
  public async getConsumable(id: string): Promise<IHttpResponse<IConsumable>> {
    return serverAxios.get(addRouteParam(ConsumablesCatalogHttp.VIEW_ROUTE, { id }));
  }
  public async getConsumableFilters(urlParams: IConsumableCatalogParams): Promise<IHttpResponse<IConsumableCatalogFilter>> {
    return serverAxios.get(ConsumablesCatalogHttp.FILTER_ROUTE, { params: urlParams });
  }
}

export interface IConsumableCatalogParams {
  vin?: string;
  source?: string;
  vehicle_identifier?: string;
  vehicle_id?: string;
  page?: number;
  category_ids?: string[];
}

export default ConsumablesCatalogHttp;
